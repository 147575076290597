import { useContext, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import { GoHeartFill } from "react-icons/go";
import PageHeader from "../PageHeader.js";
import PageFooter from "../PageFooter.js";
import { FurnitureSearch } from "../../App.js";
import FurnitureCard from "../FurnitureCard.js";
import { getReq, postReq } from "../../configs/ApiUrl.js";
import { useTranslation } from "react-i18next";
import AlwaysTop from "./util/AlwaysTop.js";

function CatalogueFavourites() {
  const { t } = useTranslation("favouritesPage");
  const [favouriteProducts, setFavouriteProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const [searchTerm, setSearchTerm] = useContext(FurnitureSearch);
  const navigateProduct = useNavigate();

  const getFavouriteProducts = async () => {
    try {
      setLoading(true);
      const userId = localStorage.getItem('userId');
      if (!userId) {
        console.error('User ID not found in local storage.');
        return;
      }

      // Fetch favourite product IDs
      const response = await getReq(`/user/${userId}/get-favourite`)
      if (response && response.success && Array.isArray(response.favouriteProducts)) {
        // Fetch details for each product
        const productDetails = await Promise.all(
          response.favouriteProducts.map(async (productId) => {
            const productResponse = await getReq(`/product/${productId}`);
            return productResponse.product; // Accessing the actual product data inside the response
          })
        );
        setFavouriteProducts(productDetails);
      } else {
        setFavouriteProducts([]);
      }
    } catch (error) {
      console.error("Error fetching favourite products:", error);
      setFavouriteProducts([]); // Ensure favouriteProducts is an array even on error
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getFavouriteProducts();
    AlwaysTop();
  }, []);

  const handleProductSelect = (productId) => {
    setSearchTerm({
      ...searchTerm,
      searchFilter: favouriteProducts.filter((x) => x._id === productId),
      productDisplayMain: "hidden",
      productDisplayZoom: "visible",
      productArrow: "hidden",
      generalArrow: "visible",
      arrowNavigation: "/catalogue-favourites",
      searchID: productId,
    });

    navigateProduct(`/catalogue-product/${productId}`);
  };

  const removeFavourite = async (index, productId) => {
    try {
      const userId = localStorage.getItem('userId');
      if (!userId) {
        console.error('User ID not found in local storage.');
        return;
      }

      const response = await postReq('/user/remove-favourite', {
        userId,
        productId
      });

      if (response && response.message) {
        console.log('Product removed from favourites:', response.message);

        let updatedFavourites = [...favouriteProducts];
        updatedFavourites.splice(index, 1);
        setFavouriteProducts(updatedFavourites);

        let updatedSearchTermFavourites = [...searchTerm.favourites];
        updatedSearchTermFavourites = updatedSearchTermFavourites.filter(id => id !== productId);
        setSearchTerm({
          ...searchTerm,
          favourites: updatedSearchTermFavourites,
        });
      } else {
        console.error("Failed to remove product from favourites.");
      }
    } catch (error) {
      console.error("Error removing product from favourites:", error);
    }
  };

  return (
    <>
      <div className='flex flex-col items-center min-h-screen' style={{ backgroundColor: "#F4F5F9" }}>
        <div className='mb-12'>
          <PageHeader />
        </div>
        <div className='max-w-md w-full'>
          <header className='p-6 mt-4 rounded-b-2xl font-bold text-lg bg-white shadow-md'>
            <div className='relative flex items-center justify-center max-w-screen-xl mx-auto'>
              <Link to='/shopping-home' className='back-btn absolute left-0 cursor-pointer'>
                <img src='./../assets/icons/arrow.svg' alt={t("altText:backArrow")} width={25} />
              </Link>
              <h1 className='text-2xl'>{t("favHeader")}</h1>
              <div className='absolute right-0 mx-4' />
            </div>
          </header>
          <main>
            {favouriteProducts.length === 0 ? (
              <div className='flex flex-col items-center justify-center mt-24 pb-24'>
                <GoHeartFill size={120} fill='none' stroke='#E16A2E' strokeWidth={2} />
                <h2 className='text-center text-2xl p-2 font-bold'>
                  {t("favSubheader")}
                </h2>
                <p className='p-2 text-center text-stone-400'>{t("favEmpty")}</p>
              </div>
            ) : (
              <div className='grid grid-cols-1 gap-2 p-4'>
                {favouriteProducts.map((currentObject, i) => (
                  currentObject && (
                    <div
                      className='border-2 pt-8 pb-2 mb-2 rounded-xl relative bg-white drop-shadow'
                      key={i}
                      onClick={() => handleProductSelect(currentObject?._id)}>
                      <FurnitureCard
                        key={currentObject?._id}
                        id={currentObject?._id}
                        name={currentObject?.title}
                        price={currentObject?.price != null ? currentObject.price : 0}
                        url={Array.isArray(currentObject?.imageNames) && currentObject.imageNames.length > 0 ? currentObject.imageNames[0] : "default-image-url.jpg"}
                        moving={currentObject?.deliveryPrice ?? 0}
                        condition={currentObject?.condition}
                        showCondition={false}
                        verified={currentObject?.isQualityVerified}
                      />
                      <div
                        className='text-black absolute right-2 z-10 top-1 cursor-pointer'
                        onClick={(e) => {
                          e.stopPropagation(); // Prevents triggering the product select even
                          removeFavourite(i, currentObject._id);
                        }}>
                        <IoMdClose size={20} key={i} id={i} name='removeItem' />
                      </div>
                    </div>
                  )
                ))}
              </div>
            )}
          </main>
          <footer>
            <PageFooter />
          </footer>
        </div>
      </div>
    </>
  );
}

export default CatalogueFavourites;