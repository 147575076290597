import { Link } from "react-router-dom";
import PageFooter from "../PageFooter.js";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getReq } from "../../configs/ApiUrl.js";
import PageHeader from "../PageHeader.js";
import { LoadingSpinner } from "./util/LoadingSpinner.js";
import locationMark from "../../images/locationMark.svg";
import AlwaysTop from "./util/AlwaysTop.js";
import PageSubheader from "../PageSubheader.js";

function UserBuy() {
  const { t } = useTranslation(["userProfile", "tables", "altText", "admin"]);
  const [loading, setLoading] = useState(false);
  const [customerOrders, setCustomerOrders] = useState([]);
  const userInfo = JSON.parse(localStorage.getItem("user"));

  const getCustomerOrders = async () => {
    setLoading(true);
    try {
      if (userInfo?._id) {
        const url = `/order/customer/${userInfo?._id}`;
        const ordersResp = await getReq(url);
        if (ordersResp?.success) {
          setCustomerOrders(ordersResp?.customerOrders);
        } else {
          console.error("Failed to fetch orders");
        }
      } else {
        console.error("User ID not found in localStorage");
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCustomerOrders();
  }, []);

  useEffect(() => {
    AlwaysTop();
  }, []);

  const renderOrdersTable = () => {
    if (loading) {
      return <LoadingSpinner />;
    }
    if (customerOrders.length === 0) {
      return (
        <div className='flex items-center justify-center w-full h-96'>
          <h1 className='text-xl font-bold text-center text-gray-500'>
            {t("admin:noHistory")}
          </h1>
        </div>
      );
    }
    return (
      <table className='table-auto w-full border-collapse rounded-t-xl -p-1 text-center'>
        <thead>
          <tr>
            <th className='px-24 py-2 bg-none border-b-4 border-cromboOrangeFade text-black font-semibold'>
              {t("tables:image")}
            </th>
            <th className='px-4 border-b-4 border-cromboOrangeFade text-black font-semibold'>
              {t("tables:price")}
            </th>
            <th className='px-4 border-b-4 border-cromboOrangeFade text-black font-semibold'>
              {t("tables:address")}
            </th>
          </tr>
        </thead>
        <tbody>
          {customerOrders?.map((order, orderIndex) =>
            order.orderItems
              ?.filter((item) => item.product.status === "sold")
              .map((item, itemIndex) => (
                <tr
                  key={`${orderIndex}-${itemIndex}`}
                  className='hover:bg-background'>
                  <td className='relative border-b-4 border-cromboOrangeFade'>
                    <div className='flex flex-col items-center'>
                      {item?.product?.imageNames &&
                      item?.product?.imageNames[0] ? (
                        <img
                          className='w-full max-w-sm h-auto mx-auto'
                          src={item?.product.imageNames[0]}
                          alt={t("altText:firstImage")}
                        />
                      ) : (
                        <div>No image available</div>
                      )}
                      <div className='table-overlay'>
                        <span className='truncate text-sm'>
                          {item?.product?.title?.length > 20
                            ? item?.product?.title.substring(0, 15) + "..."
                            : item?.product?.title}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className='flex-col justify-start px-16 py-4 bg-background border-b-4 border-cromboOrangeFade text-slate-500'>
                    <div className='flex items-center justify-between mb-1'>
                      <div className='text-sm mr-2 mb-0.5 whitespace-nowrap'>
                        {t("admin:price")}
                      </div>
                      <div className='text-xl font-bold whitespace-nowrap'>
                        {item.product?.price ?? 0} kr.
                      </div>
                    </div>
                    <div className='flex items-center justify-between mb-1'>
                      <div className='text-sm mr-2 -mb-1 whitespace-nowrap'>
                        {t("admin:deliveryPriceLabel")}
                      </div>
                      <div className='text-xl font-bold whitespace-nowrap'>
                        {item.product?.deliveryPrice ?? 0} kr.
                      </div>
                    </div>
                    <div className='whitespace-pre-wrap mb-2'>
                      {Array.isArray(item.product?.addOns) &&
                      item.product?.addOns.length > 0 ? (
                        item.product?.addOns.map((addon, index) => (
                          <div
                            key={index}
                            className='flex items-center justify-between mb-1'>
                            <div className='text-sm mr-2 -mb-1 whitespace-nowrap'>
                              {addon.item}
                            </div>
                            <div className='text-xl font-bold whitespace-nowrap'>
                              {addon.price ?? 0} kr.
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className='flex items-center justify-between mb-1'>
                          <div className='text-sm mr-2 -mb-1 whitespace-nowrap'>
                            {t("admin:noAddons")}
                          </div>
                          <div className='text-xl font-bold whitespace-nowrap'>
                            0 kr.
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='flex items-center justify-between mb-1'>
                      <div className='text-sm mr-2 mb-0.5 whitespace-nowrap'>
                        {t("productPage:bonusDiscountLabel")}
                      </div>
                      <div className='text-xl font-bold whitespace-nowrap'>
                        {item.product?.bonusDiscount ?? 0} kr.
                      </div>
                    </div>
                    <div className='flex items-center justify-between mb-1'>
                      <div className='text-sm mr-2 mb-0.5 whitespace-nowrap'>
                        {t("admin:discount")}
                      </div>
                      <div className='text-xl font-bold whitespace-nowrap'>
                        {order.orderItems?.[0]?.extraTotal ?? 0} kr.
                      </div>
                    </div>
                    <div className='flex items-center justify-between mb-1'>
                      <div className='text-sm mr-2 font-bold text-slate-800 mb-0.5 whitespace-nowrap'>
                        {t("admin:grandTotalHeader")}
                      </div>
                      <div className='text-xl font-bold text-cromboOrange whitespace-nowrap'>
                        {order.orderItems?.[0]?.grandTotal ?? "N/A"} kr.
                      </div>
                    </div>
                  </td>
                  <td
                    className='px-12 py-2 bg-background border-b-cromboOrangeFade border-4 border-r-2 border-white text-slate-500'
                    style={{ width: 200, whiteSpace: "normal" }}>
                    <div className='flex flex-col items-center'>
                      <img
                        className='w-8 h-8 mb-2'
                        src={locationMark}
                        alt={t("altText:location")}
                      />
                      <span className='text-center'>{`${order?.shippingAddress?.street}, ${order?.shippingAddress?.state}, ${order?.shippingAddress?.city}.`}</span>
                    </div>
                  </td>
                </tr>
              ))
          )}
        </tbody>
      </table>
    );
  };

  return (
    <>
      <article>
        <PageHeader />
      </article>
      <div className='flex flex-col items-center min-h-screen mt-14'>
      <PageSubheader
        backLink="/user-profile" 
        title={t("userProfile:productsBoughtButton")}
        description={t("userProfile:orderHistoryExplainer")}
      />
        <main className='flex flex-col justify-center mb-10 bg-white w-full drop-shadow'>
          <article className='mb-10 ml-4'>
            <div className='mt-2'>
              <div className='overflow-x-auto'>{renderOrdersTable()}</div>
            </div>
          </article>
        </main>
        <footer>
        <PageFooter />
        </footer>
      </div>
    </>
  );
}

export default UserBuy;
