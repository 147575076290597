import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { postReq } from "../../configs/ApiUrl.js";
import { useTranslation } from "react-i18next";
import PageHeader from "../PageHeader.js";
import PageFooter from "../PageFooter.js";
import { IoMdContact } from "react-icons/io";
import {
  MdKeyboardArrowRight,
  MdAttachMoney,
  MdHistory,
  MdAdminPanelSettings,
  MdLogout,
} from "react-icons/md";
import { HiTruck } from "react-icons/hi";

function UserProfile() {
  const { t } = useTranslation(["userProfile", "altText", "subadmin"]);
  const navigateLogout = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [isSubadmin, setIsSubadmin] = useState(false);

  useEffect(() => {
    try {
      const userString = localStorage.getItem("user");
      if (userString) {
        const user = JSON.parse(userString);
        setUserInfo(user);
        if (user.role === "subadmin") {
          setIsSubadmin(true);
        }
      } else {
        navigateLogout("/welcome-login"); // Redirect if user data is missing
      }
    } catch (error) {
      console.error("Failed to parse user data:", error);
      navigateLogout("/welcome-login"); // Redirect if parsing fails
    }
  }, [navigateLogout]);

  const handleLogOut = async () => {
    try {
      setLoading(true);
      const logOutResp = await postReq("/user/logout");
      if (logOutResp?.success) {
        setLoading(false);
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        localStorage.removeItem("user");
        localStorage.removeItem("role");
        navigateLogout("/welcome-login");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  if (!userInfo) {
    return <div>Loading...</div>; // Display a loading message while userInfo is being fetched
  }

  return (
    <div className='flex flex-col items-center min-h-screen'>
      <div className='w-full relative'>
        <header className='mt-24'>
          <article>
            <PageHeader />
          </article>
          <article className='flex items-center justify-between p-6 -mt-8 font-bold text-lg bg-white shadow-md'>
            <Link
              to='/shopping-home'
              className='flex inline-flex back-btn mx-4'>
              <img
                src='./../assets/icons/arrow.svg'
                alt={t("altText:backArrow")}
                width={25}
              />
            </Link>
            <div className='text-2xl font-semibold flex-grow text-center'>
              {(userInfo?.username ? userInfo?.username : "User") + t("title")}
            </div>
            <div
              className='flex-shrink-0'
              style={{ width: 50 }}></div>
          </article>
        </header>
        {/* Menu providing links to page components to update user contact information, to view items to be bought or sold
                and a logout. */}
        <main className='-mt-4'>
          <div className='drop-shadow bg-white pb-2'>
            <Link to='/user-contact'>
              <button className='rounded-2xl flex items-center justify-between my-2 w-full px-4 py-2 hover:text-cromboOrangeFade'>
                <div className='flex items-center'>
                  <IoMdContact
                    className='mr-4'
                    size={45}
                  />
                  <h2 className='text-lg font-semibold'>
                    {t("contactInfoButton")}
                  </h2>
                </div>
                <div className='flex items-center ml-auto hover:text-cromboOrangeFade'>
                  <MdKeyboardArrowRight
                    className='text-cromboOrange'
                    size={45}
                  />
                </div>
              </button>
            </Link>
            <hr className='mx-4 border-t-1 border-gray-200' />
            <Link to='/approved-delivery'>
              <button className='rounded-2xl flex items-center justify-between my-2 w-full px-4 py-2 hover:text-cromboOrangeFade'>
                <div className='flex items-center'>
                  <HiTruck
                    className='mr-4'
                    size={45}
                  />
                  <h2 className='text-lg font-semibold'>
                    {t("approvedDeliveryButton")}
                  </h2>
                </div>
                <div className='flex items-center ml-auto hover:text-cromboOrangeFade'>
                  <MdKeyboardArrowRight
                    className='text-cromboOrange'
                    size={45}
                  />
                </div>
              </button>
            </Link>
            <hr className='mx-4 border-t-1 border-gray-200' />
            <Link to='/user-buy'>
              <button className='rounded-2xl flex items-center justify-between my-2 w-full px-4 py-2 hover:text-cromboOrangeFade'>
                <div className='flex items-center'>
                  <MdHistory
                    className='mr-4'
                    size={45}
                  />
                  <h2 className='text-lg font-semibold'>
                    {t("productsBoughtButton")}
                  </h2>
                </div>
                <div className='flex items-center ml-auto'>
                  <MdKeyboardArrowRight
                    className='text-cromboOrange hover:text-cromboOrangeFade'
                    size={45}
                  />
                </div>
              </button>
            </Link>
            <hr className='mx-4 border-t-1 border-gray-200' />
            <Link to='/user-sell'>
              <button className='rounded-2xl flex items-center justify-between my-2 w-full px-4 py-2 hover:text-cromboOrangeFade'>
                <div className='flex items-center'>
                  <MdAttachMoney
                    className='mr-4'
                    size={45}
                  />
                  <h2 className='text-lg font-semibold'>
                    {t("productSaleButton")}
                  </h2>
                </div>
                <div className='flex items-center ml-auto'>
                  <MdKeyboardArrowRight
                    className='text-cromboOrange hover:text-cromboOrangeFade'
                    size={45}
                  />
                </div>
              </button>
            </Link>
            <hr className='mx-4 border-t-1 border-gray-200' />
            {isSubadmin && (
              <Link to='/subadmin-menu'>
                <button className='rounded-2xl flex items-center justify-between my-2 w-full px-4 py-2 hover:text-cromboOrangeFade'>
                  <div className='flex items-center'>
                    <MdAdminPanelSettings
                      className='mr-4'
                      size={45}
                    />
                    <h2 className='text-lg font-semibold'>
                      {t("subadmin:subadminPortal")}
                    </h2>
                  </div>
                  <div className='flex items-center ml-auto'>
                    <MdKeyboardArrowRight
                      className='text-cromboOrange hover:text-cromboOrangeFade'
                      size={45}
                    />
                  </div>
                </button>
              </Link>
            )}
            <hr className='mx-4 border-t-1 border-gray-200' />
            <Link>
              <button
                onClick={handleLogOut}
                className='rounded-2xl flex items-center justify-between my-2 w-full px-4 py-2 hover:text-red-400'>
                <div className='flex items-center'>
                  <MdLogout
                    className='mx-2 mr-3 text-red-600'
                    size={40}
                  />
                  <h2 className='text-red-600 text-lg font-semibold hover:text-red-400'>
                    {t("logoutButton")}
                  </h2>
                </div>
              </button>
            </Link>
          </div>
        </main>
        <PageFooter />
      </div>
    </div>
  );
}

export default UserProfile;
