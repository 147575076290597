import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getReq } from "../../configs/ApiUrl.js";
import { LoadingSpinner } from "./util/LoadingSpinner.js";
import defaultImg from "../../images/defaultImg.webp";
import { useTranslation } from "react-i18next";
import { HiOutlineArrowLongLeft, HiPlus } from "react-icons/hi2";
import PageSubheader from "../PageSubheader.js";

function UserSell() {
  const { t } = useTranslation(["userProfile", "tables", "altText", "subadmin"]);
  const [loading, setLoading] = useState(false);
  const [myProducts, setMyProducts] = useState([]);

  const getMyProducts = async () => {
    setLoading(true);
    const userInfo = JSON.parse(localStorage.getItem("user"));
    if (userInfo?._id) {
      const myProductsResp = await getReq(`/product/user/${userInfo?._id}`);
      if (myProductsResp?.success) {
        setMyProducts(myProductsResp?.myProducts);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getMyProducts();
  }, []);

  const getClassNames = (status, index) => {
    const baseCircle = "w-12 h-12 rounded-full flex items-center justify-center";
    const baseLine = "flex-1 h-1 mb-6 min-w-[20px]";
    switch (status) {
      case "pending":
        return index === 0
          ? { circle: `${baseCircle} bg-green-500`, line: `${baseLine} bg-gray-300`, showCheck: true }
          : { circle: `${baseCircle} bg-white border-2 border-gray-300`, line: `${baseLine} bg-gray-300`, showCheck: false };
      case "approved":
        return index === 0
          ? { circle: `${baseCircle} bg-green-500`, line: `${baseLine} bg-green-500`, showCheck: true }
          : index === 1
          ? { circle: `${baseCircle} bg-green-500`, line: `${baseLine} bg-gray-300`, showCheck: true }
          : { circle: `${baseCircle} bg-white border-2 border-gray-300`, line: `${baseLine} bg-gray-300`, showCheck: false };
      case "sold":
        return index <= 2
          ? { circle: `${baseCircle} bg-green-500`, line: `${baseLine} bg-green-500`, showCheck: true }
          : { circle: `${baseCircle} bg-white border-2 border-gray-300`, line: `${baseLine} bg-gray-300`, showCheck: false };
      case "deliveryApproved":
        return { circle: `${baseCircle} bg-green-500`, line: `${baseLine} bg-green-500`, showCheck: true };
      default:
        return { circle: `${baseCircle} bg-white border-2 border-gray-300`, line: `${baseLine} bg-gray-300`, showCheck: false };
    }
  };

  const getStatusMessage = (status, title) => {
    switch (status) {
      case "pending":
        return `${title} is awaiting admin approval`;
      case "approved":
        return `${title} is live on our marketplace`;
      case "sold":
        return `${title} is due to be delivered to the buyer`;
      case "deliveryApproved":
        return `${title} has been delivered`;
      default:
        return "";
    }
  };

  return (
    <div className="flex flex-col items-center">
    <PageSubheader
        backLink="/user-profile" 
        title={t("userProfile:productSaleButton")}
        description={t("userProfile:soldProductsExplainer")}
      />
      <main className="w-full flex flex-col justify-center bg-white drop-shadow">
        <div className="overflow-x-auto">
          {loading ? (
            <LoadingSpinner />
          ) : myProducts.length > 0 ? (
            <table className="table-auto w-full border-collapse rounded-t-xl -p-1 text-center">
              <tbody>
                {myProducts.map((product) => {
                  const statusMessage = getStatusMessage(product.status, product.title);

                  return (
                    <tr key={product._id} className="hover:bg-background">
                      <td className="relative border-b-4 border-b-cromboOrangeFade">
                        <div className="flex flex-col items-center">
                          <img
                            className="w-full min-w-[200px] max-w-xs h-auto mx-auto object-cover"
                            src={product.mainImage || product.imageNames[0] || defaultImg}
                            alt={t("altText:firstImage")}
                            onError={(e) => { e.target.src = defaultImg; }}
                          />
                          <div className="table-overlay">
                            <span className="text-xs truncate">
                              {product.title.length > 10
                                ? `${product.title.substring(0, 15)}...`
                                : product.title}
                            </span>
                          </div>
                        </div>
                      </td>

                      <td className="flex-col justify-start px-12 py-4 bg-background border-b-4 border-cromboOrangeFade text-slate-500">
                        <div className="flex items-center w-full">
                          {["Added", "Approved", "Bought", "Delivered"].map((label, index) => {
                            const { circle, line, showCheck } = getClassNames(product.status, index);
                            return (
                              <React.Fragment key={index}>
                                {index !== 0 && <div className={line}></div>}
                                <div className="relative flex flex-col items-center mx-2">
                                  <div className={circle}>
                                    {showCheck && (
                                      <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                      </svg>
                                    )}
                                  </div>
                                  <div className="mt-2 font-semibold text-lg">{label}</div>
                                </div>
                              </React.Fragment>
                            );
                          })}
                        </div>
                        <p className="mt-8 text-md font-semibold text-gray-500">{statusMessage}</p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div className="flex items-center justify-center w-96 h-96 bg-white">
              <h1 className="text-xl font-bold text-center text-gray-500">
                {t("userProfile:noProducts")}
              </h1>
            </div>
          )}
        </div>
        <div className="flex justify-center my-12">
          <Link to="/selling-add-furniture">
            <button className="flex items-center text-cromboOrange font-bold text-2xl">
              <HiPlus className="mr-2 h-20 w-20" alt={t("altText:plus")} />
              {t("subadmin:newProductButton")}
              </button>
          </Link>
        </div>
      </main>
    </div>
  );
}

export default UserSell;