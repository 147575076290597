import { useContext, useState, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { useNavigate, Link } from "react-router-dom";
import PageHeader from "../PageHeader.js";
import PageFooter from "../PageFooter.js";
import { FurnitureSearch } from "../../App.js";
import OrderCard from "./util/OrderCard.js";
import { useTranslation } from "react-i18next";
import DiscountCodeCheck from "./util/DiscountCodeCheck.js";
import AlwaysTop from "./util/AlwaysTop.js";

function CatalogueCart() {
  const { t } = useTranslation(["cartPage", "altText"]);

  // context variables to retrieve contents previously stored in shopping cart
  const [searchTerm, setSearchTerm] = useContext(FurnitureSearch);
  let shoppingCart = searchTerm.shoppingCart || [];

  // useNavigate hook used to direct the user to the calendar page.
  const navigate = useNavigate();

  // Toggles the visibility of the checkout button, hiding it when the cart is empty.
  const [isVisible, setIsVisible] = useState(true);

  // Ensure all values are numbers and provide default values if not present
  const sanitizeItem = (item) => {
    const productCost = item.productCost != null ? Number(item.productCost) : 0;
    const movingCost = item.movingCost != null ? Number(item.movingCost) : 0;
    const extraTotal = item.extraTotal != null ? Number(item.extraTotal) : 0;
    const discount = item.discount != null ? Number(item.discount) : 0;
    const subadminEmail = item.subadminEmail || "";

    // Bonus discount is already calculated correctly in orderDisplay
    const bonusDiscount =
      item.bonusDiscount != null ? Number(item.bonusDiscount) : 0;

    const grandTotal =
      productCost + movingCost + extraTotal - bonusDiscount - discount;

    const sanitizedItem = {
      ...item,
      grandTotal,
      movingCost,
      productCost,
      extras: Array.isArray(item.extras) ? item.extras : [],
      extraQuantity: Array.isArray(item.extraQuantity)
        ? item.extraQuantity
        : [],
      extraCost: Array.isArray(item.extraCost) ? item.extraCost : [],
      extraTotal,
      bonusDiscount,
      discount,
      subadminEmail,
    };
    return sanitizedItem;
  };

  const sanitizedCart = shoppingCart.map(sanitizeItem);

  // Grand total state
  const [grandTotal, setGrandTotal] = useState(() => {
    const initialTotal = sanitizedCart.reduce(
      (total, item) => total + item.grandTotal,
      0
    );
    return initialTotal;
  });

  // Captures the total before the discount is applied to display as struck through
  const originalTotal = sanitizedCart.reduce(
    (total, item) => total + item.grandTotal,
    0
  );

  useEffect(() => {
    if (shoppingCart.length === 0) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  }, [shoppingCart]);

  useEffect(() => {
    // Update grand total whenever the shopping cart changes
    const updatedTotal = sanitizedCart.reduce((total, item) => {
      return total + item.grandTotal;
    }, 0);
    setGrandTotal(updatedTotal);
  }, [shoppingCart]);

  const handleCheckout = () => {
    navigate("/checkout", {
      state: {
        grandTotal,
        shoppingCart: sanitizedCart,
        deliverySlots: [],
      },
    });
  };

  // A message to be displayed to the user when the shopping cart is empty.
  function emptyCart() {
    if (shoppingCart.length === 0) {
      return (
        <div className='flex flex-col items-center justify-center mt-12 mb-24'>
          <img
            src='/assets/icons/bag-icon.svg'
            alt={t("altText:logo")}
            width={120}
          />
          <h2 className='text-center text-2xl p-2 font-bold'>
            {t("cartSubheader")}
          </h2>
          <p className='text-center text-stone-400'>{t("cartEmpty")}</p>
        </div>
      );
    }
  }

  useEffect(() => {
    AlwaysTop();
  }, []);

  return (
    // Elements centred on a narrow page format.
    <div
      className='flex flex-col items-center min-h-screen mt-6'
      style={{ backgroundColor: "#F4F5F9" }}>
      <div className='mb-10'>
        <PageHeader />
      </div>
      <div className='max-w-md w-full'>
        <header className='p-6 rounded-b-2xl font-bold text-lg bg-white shadow-md'>
          <div className='relative flex items-center justify-center max-w-screen-xl mx-auto'>
            <Link
              to='/shopping-home'
              className='back-btn absolute -left-2 cursor-pointer'>
              <img
                src='./../assets/icons/arrow.svg'
                alt={t("altText:backArrow")}
                width={25}
              />
            </Link>
            <h1 className='text-2xl'>{t("cartHeader")}</h1>
          </div>
          {/* Number of items in the shopping cart. */}
          <div className='text-center text-stone-400'>
            {shoppingCart.length === 1
              ? shoppingCart.length + t("cartSingular")
              : shoppingCart.length + t("cartPlural")}
          </div>
        </header>
        <div className='max-w-md'>
          {/* Function called to determine whether an empty shopping cart message needs to be displayed. */}
          <div className='pl-6 pr-3 mb-2 pt-2'>{emptyCart()}</div>
          <div>
            {sanitizedCart.map((currentObject, i) => (
              <div
                className='relative'
                key={currentObject.id || i}>
                <div className='text-red-500 hover:text-red-400 absolute right-7 z-10 top-2 cursor-pointer drop-shadow-lg'>
                  <div className='border-4 bg-white border-white rounded-lg'>
                    <IoMdClose
                      size={20}
                      key={i}
                      id={i}
                      onClick={() => {
                        setSearchTerm((prev) => {
                          const newShoppingCart = [...prev.shoppingCart];
                          newShoppingCart.splice(i, 1);
                          return {
                            ...prev,
                            shoppingCart: newShoppingCart,
                          };
                        });
                      }}
                    />
                  </div>
                </div>
                <OrderCard
                  name={currentObject.name}
                  quantity={currentObject.quantity}
                  productCost={currentObject.productCost}
                  movingCost={currentObject.movingCost}
                  extras={currentObject.extras}
                  extraQuantity={currentObject.extraQuantity}
                  extraCost={currentObject.extraCost}
                  extraTotal={currentObject.extraTotal}
                  bonusDiscount={currentObject.bonusDiscount}
                  discount={currentObject.discount}
                  grandTotal={currentObject.grandTotal}
                  image={currentObject.image}
                />
              </div>
            ))}
          </div>
          {sanitizedCart.length > 0 && (
            <>
              <DiscountCodeCheck
                grandTotal={grandTotal}
                setGrandTotal={setGrandTotal}
                shoppingCart={sanitizedCart} // Pass sanitizedCart as a prop
              />
              <div className='flex justify-center text-right mt-4 p-2 bg-white shadow-md rounded-2xl'>
                <div className='flex justify-center mt-8 mb-8'>
                  <h3 className='text-2xl text-center text-cromboOrange font-bold'>
                    {t("cartTotal")}{" "}
                    {grandTotal
                      .toFixed(0)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                    kr.
                  </h3>
                </div>
                {originalTotal !== grandTotal && (
                  <div className='flex justify-center text-cromboOrange font-bold text-3xl mt-6 mb-8'>
                    <span className='text-stone-500 line-through ml-2 text-xl'>
                      {originalTotal
                        ?.toFixed(0)
                        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                      kr.
                    </span>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>

      {/* "Proceed to Checkout" button to be clicked once user has finalised order. Navigate hook takes user to 
        useNavigate hook takes the user to the calendar page to select dates for delivery. This button is inactive
        when no items are in the shopping cart. */}
      <div
        className={`btn-main flex items-center justify-center mt-12 mb-8 ${
          isVisible ? "" : "hidden"
        }`}
        onClick={handleCheckout}>
        {t("checkoutButton")}
      </div>

      <footer>
        <PageFooter />
      </footer>
    </div>
  );
}

export default CatalogueCart;
