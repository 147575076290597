import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import LanguageSelect from "../components/pages/util/LanguageSelect";

export default function PageHeader() {
  const { t } = useTranslation('altText');
  return (
    <div>
        <div className="fixed top-0 left-0 right-0 bg-cromboGray flex justify-between items-center px-1 py-2 shadow-md z-50 h-16">
            <Link to='/shopping-home'>
                <img alt={t('logo')} src="./../assets/images/24Mobler.png" className="ml-4 w-48 lg:w-80"></img>
                </Link>
            <LanguageSelect/>
        </div>
    </div>
  )
}
