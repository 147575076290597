import { Link } from "react-router-dom";
import { HiOutlineArrowLongLeft } from "react-icons/hi2";
import PageFooter from "../PageFooter.js";
import { useEffect, useState } from "react";
import { getReq } from "../../configs/ApiUrl.js";
import { LoadingSpinner } from "./util/LoadingSpinner.js";
import defaultImg from "../../images/defaultImg.webp";
import moment from "moment";
import { useTranslation } from "react-i18next";
import PageHeader from "../PageHeader.js";
import AlwaysTop from "./util/AlwaysTop.js";
import PageSubheader from "../PageSubheader.js";

function ApprovedDelivery() {
  const { t } = useTranslation(["userProfile", "tables", "altText", "admin"]);
  const [customerOrders, setCustomerOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem("user"));

  const getCustomerOrders = async () => {
    setLoading(true);
    if (userInfo?._id) {
      try {
        const ordersResp = await getReq(`/order/customer/${userInfo?._id}`);
        if (ordersResp?.success) {
          setCustomerOrders(ordersResp?.customerOrders || []);
          if (!ordersResp.customerOrders.length) {
          }
        } else {
          console.error(
            "Failed to fetch orders:",
            ordersResp?.message || "Unknown error"
          );
        }
      } catch (error) {
        console.error("Error fetching customer orders:", error);
      }
    } else {
      console.error("User ID is missing");
    }
    setLoading(false);
  };

  useEffect(() => {
    getCustomerOrders();
  }, []);

  useEffect(() => {
    AlwaysTop();
  }, []);

  function myApprovedDeliveries() {
    if (loading) {
      return <LoadingSpinner />;
    }

    // Check if there are any orders or if any nested arrays are empty
    if (
      !customerOrders.length ||
      !customerOrders.some(
        (order) => Array.isArray(order?.orderItems) && order.orderItems.length
      )
    ) {
      return (
        <div className='flex items-center justify-center w-full h-96'>
          <h1 className='text-xl font-bold text-center text-gray-500'>
            {t("admin:noOrders")}
          </h1>
        </div>
      );
    }

    return (
      <div className='overflow-x-auto'>
        <table className='table-auto w-full border-collapse rounded-t-xl text-center'>
          <thead>
            <tr>
              <th className='px-4 py-2 bg-none border-b-4 border-cromboOrangeFade text-black font-semibold'>
                {t("tables:image")}
              </th>
              <th className='px-4 border-b-4 border-cromboOrangeFade text-black font-semibold'>
                {t("tables:deliverySlots")}
              </th>
            </tr>
          </thead>
          <tbody>
            {customerOrders.map(
              (order) =>
                Array.isArray(order?.orderItems) &&
                order?.orderItems.map((item) => (
                  <tr
                    key={item?.id}
                    className='hover:bg-gray-50'>
                    <td className='relative py-2 bg-background border-2'>
                      <div className='w-48 h-48 mx-auto flex items-center justify-center overflow-hidden bg-gray-100'>
                        <img
                          className='object-cover w-full h-full'
                          src={`${
                            item?.product?.mainImage ||
                            (item?.product?.imageNames?.length > 0 &&
                              item?.product?.imageNames[0]) ||
                            defaultImg
                          }`}
                          alt={t("altText:firstImage")}
                          onError={(e) => {
                            e.target.src = defaultImg;
                          }}
                        />
                      </div>
                      <div className='table-overlay'>
                        <div>
                          {item?.product?.title?.length > 10
                            ? item?.product?.title.substring(0, 15) + "..."
                            : item?.product?.title}
                        </div>
                        <div>{item?.product?.price} kr.</div>
                      </div>
                    </td>
                    <td className='px-4 py-2 bg-background border-2'>
                      <div className='flex-col justify-center'>
                        {Array.isArray(order?.deliverySlots) &&
                        order.deliverySlots.length > 0 ? (
                          order.deliverySlots.map((slot, ind) => (
                            <div
                              key={ind}
                              className='ml-2 mb-1 px-2 py-2 flex flex-col justify-center items-center bg-white drop-shadow rounded'>
                              <span className='text-cromboOrangeFade text-sm'>
                                {moment(slot?.date).format("DD/MM/YYYY")}
                              </span>
                              <span className='text-xs'>{slot?.time}</span>
                            </div>
                          ))
                        ) : (
                          <span className='text-gray-500'>
                            {t("admin:noDeliverySlots")}
                          </span>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
            )}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div className='flex flex-col items-center'>
      <article>
        <PageHeader />
      </article>
      <div className='w-full md:max-w-3xl lg:max-w-4xl relative mt-14 bg-white'>
      <PageSubheader
        backLink="/user-profile" 
        title={t("userProfile:approvedDeliveryButton")}
        description={t("userProfile:ActiveOrdersExplainer")}
      />
        <main className='flex flex-col justify-center mb-10 w-full'>
          <article className='mb-10'>
            <div>{myApprovedDeliveries()}</div>
          </article>
        </main>
        <div className='mb-72'></div>
        <footer>
        <PageFooter />
        </footer>
      </div>
    </div>
  );
}

export default ApprovedDelivery;
