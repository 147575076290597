import { useEffect, useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { postReq, getReq } from "../../configs/ApiUrl.js";
import MoblerHeader from "../MoblerHeader.js";
import AlwaysTop from "./util/AlwaysTop.js";
import InputField from "../InputField.js";
import AuthContext from "./util/AuthContext.js";

function SubadminLogin() {
  const { t } = useTranslation(["subadmin", "admin", "forms", "altText"]);

  const [background, setBackground] = useState({
    email: "bg-white",
    password: "bg-white",
  });

  const [message, setMessage] = useState("");
  const [emailCheck, setEmailCheck] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState(null);
  const [emailState, setEmailState] = useState({
    emailBackground: "",
    emailHolder: "",
  });
  const [passwordState, setPasswordState] = useState({
    passwordBackground: "",
    passwordHolder: "",
  });
  const navigateMenu = useNavigate();

  const fetchUserRole = async (userId) => {
    try {
      const roleResp = await getReq(`/user/${userId}`);
      if (roleResp?.success) {
        return roleResp.role;
      } else {
        console.error("Failed to fetch user role:", roleResp.message);
        return null;
      }
    } catch (error) {
      console.error("Error fetching user role:", error.message);
      return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;

    if (!email || !password) {
      setLoginError("Please enter your email and password.");
      return;
    }

    setIsLoading(true);

    try {
      const loginResponse = await postReq("/user/check-subadmin", {
        email,
        password,
      });

      if (loginResponse?.success) {
        login(loginResponse.user, loginResponse.token);
        navigate("/subadmin-menu");
      } else {
        setLoginError("Incorrect email or password.");
      }
    } catch (error) {
      setLoginError("An error occurred while logging in.");
      console.error("Login error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    AlwaysTop();
  }, []);

  return (
    <div className='flex flex-col items-center min-h-screen bg-background'>
      <div className='w-full bg-cromboGray'>
        <MoblerHeader />
      </div>
      <div className='max-w-md w-full px-6'>
        <header className='flex items-center pt-10 w-full'>
          <img
            alt={t("altText:verticalDivider")}
            src='./../assets/icons/divider-vertical.svg'
            className='h-10 mr-2'
          />
          <h1 className='text-3xl font-bold'>{t("subadmin:businessLogin")}</h1>
        </header>
        <main className='flex flex-col items-center w-full max-w-md mt-4'>
          <form
            onSubmit={handleSubmit}
            className='w-full'>
            <InputField
              label={t("forms:emailLabel")}
              name='email'
              value={emailState.value}
              placeholder={emailState.emailHolder}
              className={`${background.email} px-4 py-2`}
              onClick={() => {
                setBackground({
                  ...background,
                  email: "bg-white",
                });
              }}
              onChange={(e) => {
                setEmailState({
                  ...emailState,
                  value: e.target.value,
                });
              }}
            />
            <InputField
              label={t("forms:passwordLabel")}
              name='password'
              type='password'
              value={passwordState.value}
              placeholder={passwordState.passwordHolder}
              className={`${background.password} px-4 py-2`}
              onClick={() => {
                setBackground({
                  ...background,
                  password: "bg-white",
                });
              }}
              onChange={(e) => {
                setPasswordState({
                  ...passwordState,
                  value: e.target.value,
                });
              }}
            />
            <div className='flex flex-col items-center mt-8 w-full'>
              <div className='relative w-full'>
                <h2 className='text-red-500 font-semibold absolute w-full top-[-28px]'>
                  {message}
                </h2>
              </div>
              <button className='btn-main flex items-center justify-center mb-5 w-full py-2'>
                <h2 className='text-white text-lg lg:text-2xl text-center font-semibold'>
                  {t("forms:login")}
                </h2>
              </button>
            </div>
            {loginError && (
              <p className='flex justify-center items-center text-red-500'>
                {loginError}
              </p>
            )}
          </form>
          <h2 className='flex items-center text-xl font-semibold mb-12 mt-12'>
            {t("forms:userPrompt")}
            <Link to='/welcome-login'>
              <h2 className='text-cromboOrange pl-1 font-bold hover:cursor-pointer hover:underline'>
                {t("forms:login")}
              </h2>
            </Link>
          </h2>
        </main>
      </div>
    </div>
  );
}
export default SubadminLogin;
