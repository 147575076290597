import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HiOutlineArrowLongLeft, HiOutlineTrash } from "react-icons/hi2";
import { useTranslation } from "react-i18next";
import { getReq, delReq, postReq } from "../../configs/ApiUrl";
import { LoadingSpinner } from "./util/LoadingSpinner";
import DeleteWarningModal from "./util/DeleteWarningModal";
import locationMark from "../../images/locationMark.svg";
import AlwaysTop from "./util/AlwaysTop.js";
import defaultImg from "../../images/defaultImg.webp";

function ProductsHistory() {
  const { t } = useTranslation(["admin", "tables", "altText", "subadmin"]);
  const [loading, setLoading] = useState(false);
  const [historyItems, setHistoryItems] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [loadingStates, setLoadingStates] = useState({});
  const [userRole, setUserRole] = useState("");

  const fetchUserRole = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const roleResp = await getReq(`/user/${userId}`);
      if (roleResp?.success) {
        setUserRole(roleResp.role);
      } else {
        console.error("Failed to fetch user role:", roleResp.message);
      }
    } catch (error) {
      console.error("Error fetching user role:", error.message);
    }
  };

  const getAllHistoryItems = async () => {
    try {
      setLoading(true);

      const response = await getReq(`/order/all`);
      if (response?.success) {
        let filteredOrders = response.allOrders.filter((order) =>
          ["cancelled", "delivered"].includes(order.orderStatus)
        );

        if (userRole === "subadmin") {
          const user = JSON.parse(localStorage.getItem("user"));
          const userEmail = user?.email;
          if (!userEmail) {
            console.error("User email not found");
            setLoading(false);
            return;
          }

          // Filter orders by subadmin email
          filteredOrders = filteredOrders.filter(
            (order) => order.subadminEmail === userEmail
          );
        }

        const sortedOrders = filteredOrders.sort(
          (a, b) => new Date(a.orderDate) - new Date(b.orderDate)
        );
        setHistoryItems(sortedOrders);
      }
    } catch (error) {
      console.error("Error fetching history items:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDecline = async (id) => {
    try {
      setLoadingStates((prevState) => ({
        ...prevState,
        [id]: true,
      }));

      const response = await delReq(`/order/delete/${id}`, {
        status: "declined",
      });

      if (response?.success) {
        setLoadingStates((prevState) => ({
          ...prevState,
          [id]: false,
        }));
        await getAllHistoryItems();
      } else {
        setLoadingStates((prevState) => ({
          ...prevState,
          [id]: false,
        }));
      }
    } catch (error) {
      console.error("Error declining item:", error);
      setLoadingStates((prevState) => ({
        ...prevState,
        [id]: false,
      }));
    }
  };

  useEffect(() => {
    AlwaysTop();
    fetchUserRole(); // Fetch user role on component mount
  }, []);

  useEffect(() => {
    if (userRole) {
      getAllHistoryItems(); // Fetch history items after the role is determined
    }
  }, [userRole]);

  const openDeleteModal = (id) => {
    setSelectedItemId(id);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setSelectedItemId(null);
    setShowDeleteModal(false);
  };

  const handleConfirmDelete = async () => {
    if (selectedItemId) {
      await handleDecline(selectedItemId);
      closeDeleteModal();
    }
  };

  return (
    <div className='flex flex-col items-center'>
      <header className='w-full flex items-center justify-between mb-10 bg-white drop-shadow'>
        <Link
          to={userRole === "admin" ? "/admin-menu" : "/subadmin-menu"}
          className='flex inline-flex back-btn mx-4'>
          <HiOutlineArrowLongLeft
            size={30}
            color='white'
          />
        </Link>
        <article className='w-full flex-col items-center justify-center mr-16 my-6'>
          <h1 className='text-3xl font-bold flex-grow text-center'>
            {t("admin:historyButton")}
          </h1>
          <p className='mt-1 text-sm font-semibold text-center text-gray-500'>
            {userRole === "admin"
              ? t("admin:historyExplainer")
              : t("subadmin:subHistoryExplainer")}
          </p>
        </article>
      </header>

      {showDeleteModal && (
        <DeleteWarningModal
          onConfirm={handleConfirmDelete}
          onClose={closeDeleteModal}
        />
      )}

      <div className='flex flex-col items-center px-2 w-full'>
        <main className='w-full flex flex-col justify-center mb-10 bg-white drop-shadow'>
          <div className='overflow-x-auto w-full'>
            {loading ? (
              <LoadingSpinner />
            ) : historyItems.length > 0 ? (
              <table className='table-auto w-full border-collapse rounded-t-xl'>
                <thead>
                  <tr>
                    <th className='px-24 py-2 bg-none border-b-4 border-cromboOrangeFade text-black font-semibold'>
                      {t("tables:image")}
                    </th>
                    <th className='px-4 py-2 border-b-4 border-cromboOrangeFade text-black font-semibold'>
                      {t("admin:grandTotalHeader")}
                    </th>
                    {userRole === "admin" ? (
                      <>
                        <th className='px-4 py-2 border-b-4 border-cromboOrangeFade text-black font-semibold'>
                          {t("tables:address")}
                        </th>
                        <th className='px-4 py-2 border-b-4 border-cromboOrangeFade text-black font-semibold'>
                          {t("forms:phoneLabel")}
                        </th>
                      </>
                    ) : null}
                    <th className='px-2 border-b-4 border-cromboOrangeFade text-black font-semibold'>
                      {t("admin:actionHeader")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {historyItems.map((item) => {
                    const isAdmin = userRole === "admin";
                    const itemName = item.orderItems[0]?.product?.title;

                    const itemImage = item.orderItems[0]?.product?.imageNames?.[0];

                    const itemPrice = item.orderItems[0]?.product?.price;

                    const itemDeliveryPrice = item.orderItems[0]?.product?.deliveryPrice;

                    const itemAddOns = item.orderItems[0]?.product?.addOns;

                    const itemBonusDiscount = item.orderItems[0]?.product?.bonusDiscount;

                    const itemExtraTotal = item.orderItems[0]?.extraTotal;

                    const itemGrandTotal = item.orderItems[0]?.grandTotal;

                    const itemAddress = `${item?.shippingAddress?.street}, ${item?.shippingAddress?.state}, ${item?.shippingAddress?.city}.`;

                    const itemPhone = item?.shippingAddress?.phone;

                    return (
                      <tr
                        key={item._id}
                        className='hover:bg-background'>
                        <td className='relative border-b-4 border-cromboOrangeFade px-2 py-2'>
                          <div className='relative flex flex-col items-center'>
                            {itemImage ? (
                              <img
                                className='w-full max-w-xs h-auto mx-auto'
                                src={itemImage}
                                alt={t("altText:firstImage")}
                                onError={(e) => {
                                  e.target.src = defaultImg;
                                }}
                              />
                            ) : (
                              <div>No image available</div>
                            )}
                            <div className='absolute top-0 right-0 m-2'>
                              <div className='flex flex-col justify-center items-center'>
                                {(item.orderStatus === "delivered") && (
                                  <button
                                    className="w-20 h-8 bg-cromboOrangeFade text-white py-1 px-1 rounded disabled:opacity-100 disabled:cursor-not-allowed"
                                    disabled
                                  >
                                    {t("admin:receivedLabel")}
                                  </button>
                                )}

                                {(item.orderStatus === "cancelled") && (
                                  <button
                                    className="w-20 h-8 bg-red-500 text-white py-1 px-1 rounded disabled:opacity-100 disabled:cursor-not-allowed"
                                    disabled
                                  >
                                    {t("admin:declinedLabel")}
                                  </button>
                                )}
                              </div>
                            </div>
                            <div className='absolute bottom-0 left-0 bg-black bg-opacity-50 text-white text-center w-full py-1'>
                              <span className='truncate text-sm'>
                                {itemName?.length > 20
                                  ? `${itemName.substring(0, 15)}...`
                                  : itemName}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className='px-4 py-2 bg-background border-b-4 border-cromboOrangeFade text-slate-500'>
                          <div className='flex items-center justify-between mb-1'>
                            <div className='text-sm mr-2 mb-0.5 whitespace-nowrap'>
                              {t("admin:price")}
                            </div>
                            <div className='text-xl font-bold whitespace-nowrap'>
                              {itemPrice ?? 0} kr.
                            </div>
                          </div>
                          <div className='flex items-center justify-between mb-1'>
                            <div className='text-sm mr-2 -mb-1 whitespace-nowrap'>
                              {t("admin:deliveryPriceLabel")}
                            </div>
                            <div className='text-xl font-bold whitespace-nowrap'>
                              {itemDeliveryPrice ?? 0} kr.
                            </div>
                          </div>
                          <div className='whitespace-pre-wrap mb-2'>
                            {Array.isArray(itemAddOns) &&
                            itemAddOns.length > 0 ? (
                              itemAddOns.map((addon, index) => (
                                <div
                                  key={index}
                                  className='flex items-center justify-between mb-1'>
                                  <div className='text-sm mr-2 -mb-1 whitespace-nowrap'>
                                    {addon.item}
                                  </div>
                                  <div className='text-xl font-bold whitespace-nowrap'>
                                    {addon.price ?? 0} kr.
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div className='flex items-center justify-between mb-1'>
                                <div className='text-sm mr-2 -mb-1 whitespace-nowrap'>
                                  {t("admin:noAddons")}
                                </div>
                                <div className='text-xl font-bold whitespace-nowrap'>
                                  0 kr.
                                </div>
                              </div>
                            )}
                          </div>
                          <div className='flex items-center justify-between mb-1'>
                            <div className='text-sm mr-2 mb-0.5 whitespace-nowrap'>
                              {t("productPage:bonusDiscountLabel")}
                            </div>
                            <div className='text-xl font-bold whitespace-nowrap'>
                              {itemBonusDiscount ?? 0} kr.
                            </div>
                          </div>
                          <div className='flex items-center justify-between mb-1'>
                            <div className='text-sm mr-2 mb-0.5 whitespace-nowrap'>
                              {t("admin:discount")}
                            </div>
                            <div className='text-xl font-bold whitespace-nowrap'>
                              {itemExtraTotal ?? 0} kr.
                            </div>
                          </div>
                          <div className='flex items-center justify-between mb-1'>
                            <div className='text-sm mr-2 font-bold text-slate-800 mb-0.5 whitespace-nowrap'>
                              {t("admin:grandTotalHeader")}
                            </div>
                            <div className='text-xl font-bold text-cromboOrange whitespace-nowrap'>
                              {itemGrandTotal ?? "N/A"} kr.
                            </div>
                          </div>
                        </td>
                        {isAdmin && (
                          <>
                            <td className='px-4 py-2 bg-background border-b-cromboOrangeFade border-4 border-r-2 border-white text-slate-500'>
                              <div className='flex flex-col items-center'>
                                <img
                                  className='w-8 h-8 mb-2'
                                  src={locationMark}
                                  alt={t("altText:location")}
                                />
                                <span className='text-center'>
                                  {itemAddress}
                                </span>
                              </div>
                            </td>
                            <td className='px-4 py-2 bg-background border-b-cromboOrangeFade border-4 border-r-2 border-white text-slate-500'>
                              <div className='w-full py-2 flex justify-center items-start'>
                                <span className='py-1'>{itemPhone}</span>
                              </div>
                            </td>
                          </>
                        )}
                        <td className='px-4 py-2 bg-background border-2 border-b-4 border-b-cromboOrangeFade border-r text-slate-500 border-white text-center'>
                          <div className='flex flex-col sm:flex-row justify-center items-center gap-4 '>
                            <div
                              className='flex items-center justify-center p-4 rounded-lg bg-white border-2 border-white text-red-600 hover:text-red-700 cursor-pointer drop-shadow'
                              onClick={() => openDeleteModal(item._id)}>
                              <HiOutlineTrash className='h-6 w-6' />
                              <h4 className='ml-2 text-sm font-bold'>
                                {t("deleteButton")}
                              </h4>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className='flex items-center justify-center w-full h-96 bg-white'>
                <h1 className='text-xl font-bold text-center text-gray-500'>
                  {t(
                    userRole === "admin" ? "admin:noOrders" : "admin:noProducts"
                  )}
                </h1>
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}

export default ProductsHistory;
