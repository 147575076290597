import { Link, useLocation } from 'react-router-dom';
import { BiSolidHomeCircle } from 'react-icons/bi';
import { GoHeart } from 'react-icons/go';
import { FaPlusCircle } from 'react-icons/fa';
import { RxPerson } from 'react-icons/rx';
import { MdOutlineShoppingCart } from 'react-icons/md';

function CatalogueFooter() {
    const location = useLocation();

    const isActive = (path) => location.pathname === path;

    return (
        <div className="fixed bottom-0 left-0 right-0 bg-white flex justify-between px-6 py-4 shadow-md z-10">
            <Link
                to="/shopping-home"
                id="1"
                className={`flex flex-col items-center ${isActive('/shopping-home') ? 'text-cromboOrange' : 'text-black'}`}
            >
                <BiSolidHomeCircle id="1" size={25} />
                <p className="font-bold text-xs mt-1">Home</p>
            </Link>
            <Link
                to="/catalogue-favourites"
                id="2"
                className={`flex flex-col items-center ${isActive('/catalogue-favourites') ? 'text-cromboOrange' : 'text-black'}`}
            >
                <GoHeart id="2" size={25} />
                <p className="font-bold text-xs mt-1">Favourites</p>
            </Link>
            <Link
                to="/selling-add-furniture"
                id="3"
                className={`flex flex-col items-center ${isActive('/selling-add-furniture') ? 'text-cromboOrange' : 'text-black'}`}
            >
                <FaPlusCircle id="3" size={25} />
                <p className="font-bold text-xs mt-1">Sell</p>
            </Link>
            <Link
                to="/user-profile"
                id="4"
                className={`flex flex-col items-center ${isActive('/user-profile') ? 'text-cromboOrange' : 'text-black'}`}
            >
                <RxPerson id="4" size={25} />
                <p className="font-bold text-xs mt-1">Profile</p>
            </Link>
            <Link
                to="/catalogue-cart"
                id="5"
                className={`flex flex-col items-center ${isActive('/catalogue-cart') ? 'text-cromboOrange' : 'text-black'}`}
            >
                <MdOutlineShoppingCart id="5" size={25} />
                <p className="font-bold text-xs mt-1">Cart</p>
            </Link>
        </div>
    );
}

export default CatalogueFooter;
