import { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { message } from "antd";
import { FurnitureSearch } from "../../App.js";
import { postReq, getReq } from "../../configs/ApiUrl.js";
import LoadingButtonWide from "../LoadingButtonWide.js";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AlwaysTop from "./util/AlwaysTop.js";
import OrderCard from "./util/OrderCard.js";
import moment from "moment";
import { FaRegCreditCard, FaTruck } from "react-icons/fa";
import KlarnaPayment from "./util/KlarnaPayment.js";
import { OrderContext } from "./util/OrderContext.js";

function CatalogueAddress() {
  const { t } = useTranslation([
    "forms",
    "utilityPages",
    "admin",
    "checkoutPage",
  ]);
  const navigate = useNavigate();
  const location = useLocation();

  // Ensure deliverySlots is set to an empty array if no data is passed
  const {
    grandTotal = 0,
    shoppingCart = [],
    deliverySlots = [],
  } = location.state || {};

  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  let user = localStorage.getItem("user");
  if (user) {
    user = JSON.parse(user);
  }
  console.log("Initial deliverySlots from location:", deliverySlots);

  const [searchTerm, setSearchTerm] = useContext(FurnitureSearch);
  const { setOrderData } = useContext(OrderContext);

  const [formDisplay, setFormDisplay] = useState({
    defaultShipping: "visible",
    duplicateShipping: "hidden",
  });

  const [shippingAddress, setShippingAddress] = useState({
    username: user?.username || "",
    email: user?.email || "",
    street: user?.address?.street || "",
    postalCode: user?.address?.postalCode || "",
    phone: user?.address?.phone || "",
    state: user?.address?.state || "",
    city: user?.address?.city || "",
  });

  const [inputFormat2, setInputFormat2] = useState({
    userNameInput: "",
    emailInput2: "",
    streetInput2: "",
    postalCodeInput2: "",
    phoneInput2: "",
    stateInput2: "",
    cityInput2: "",
  });

  const [formAlert, setFormAlert] = useState("");
  const [emailVerify2, setEmailVerify2] = useState("");
  const [passwordVerification, setPasswordVerification] = useState("");
  const [passwordLength, setPasswordLength] = useState("");
  const [emailLoginCheck, setEmailLoginCheck] = useState("");
  const [loginInput, setLoginInput] = useState({
    loginEmail: "",
    loginPassword: "",
  });

  const [loginAlert, setLoginAlert] = useState("");

  function loginCheck() {
    if (searchTerm.loggedIn === true) {
      return "hidden";
    } else {
      return "visible";
    }
  }

  function accountCheck() {
    if (searchTerm.loggedIn === true) {
      return "hidden";
    } else {
      return "invisible";
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setShippingAddress({
      ...shippingAddress,
      [name]: value,
    });
  };

  const validateShippingAddress = () => {
    const fields = [
      "username",
      "email",
      "street",
      "postalCode",
      "phone",
      "state",
      "city",
    ];
    for (const field of fields) {
      if (!shippingAddress[field]) {
        setFormAlert(`Please complete the ${field} field.`);
        return false;
      }
    }
    return true;
  };

  const prepareOrderData = async () => {
    if (!validateShippingAddress()) {
      return false;
    }
    console.log(
      "Preparing order data. Current deliverySlots:",
      searchTerm?.deliverySlots || []
    );

    const customerId = localStorage.getItem("userId");
    const orderItems = searchTerm?.shoppingCart || [];
    const deliverySlots = searchTerm?.deliverySlots || [];

    if (!customerId || !orderItems.length) {
      message.warning("Data missing", 1);
      return false;
    }

    const productId = searchTerm.shoppingCart[0]?.id;

    if (!productId) {
      message.error("No product found in the shopping cart", 1);
      return false;
    }

    const productDetails = await fetchProductDetails(productId);

    if (!productDetails) {
      message.error("Failed to fetch product details", 1);
      return false;
    }

    const productSubadminEmail = productDetails.subadminEmail;
    const productAuthor = productDetails.productAuthor;
    const orderData = {
      customerId,
      shippingAddress,
      orderItems,
      deliverySlots,
      subadminEmail: productSubadminEmail,
      courier: productAuthor,
    };
    console.log("Order data prepared:", orderData);
    setOrderData(orderData);
    localStorage.setItem("orderData", JSON.stringify(orderData));
    return orderData;
  };

  const createOrder = async () => {
    setLoading(true);
    const orderData = await prepareOrderData();
    if (!orderData) {
      setLoading(false);
      return false;
    }
    console.log("Final order data being sent:", orderData);
    try {
      const orderCreateResp = await postReq("/order/create", orderData);
      console.log("Order creation response:", orderCreateResp);

      // Check if `createdOrder` is part of the response
      if (orderCreateResp?.createdOrder?._id) {
        console.log("Created order ID:", orderCreateResp.createdOrder._id);
        setSearchTerm({
          ...searchTerm,
          shoppingCart: [],
          deliverySlots: [],
        });
        navigate("/order-success");
        message.success("Order Success", 2);
        return true;
      } else {
        console.log("Order creation failed:", orderCreateResp);
        message.error("Order Failed", 1);
        return false;
      }
    } catch (error) {
      console.error("Error during order creation:", error);
      message.error("Order Failed", 1);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const fetchProductDetails = async (productId) => {
    try {
      const response = await getReq(`/product/${productId}`);
      return response.product;
    } catch (error) {
      console.error("Error fetching product details:", error);
      return null;
    }
  };

  const handleCreateOrder = async (e) => {
    e.preventDefault();
    setLoading(true);
    const orderData = await prepareOrderData();
    if (orderData) {
      setOrderData(orderData);
      await createOrder();
    }
    setLoading(false);
  };

  useEffect(() => {
    AlwaysTop();
  }, []);

  const handleSetTimeslots = () => {
    navigate("/calendar", {
        state: {
            grandTotal,
            shoppingCart,
            isSeller: false,
        },
    });
};

  const navigateToConfirmation = () => {
    if (orderData) {
      navigate(
        "/order-success-klarna/order-success-klarna?order_id={checkout.order.id}"
      );
    } else {
      message.error("Order data is not available");
    }
  };

  return (
    <div className='flex flex-col items-center min-h-screen'>
      <div className='max-w-md p-4 my-4 bg-white rounded-xl'>
        {!token ? (
          <form
            className={
              loginCheck() + " mt-16 flex flex-col items-center w-full"
            }
            onSubmit={handleSignIn}>
            <div className='w-full mb-6'>
              <div className='relative'>
                <div className='absolute top-[-32px] left-4 text-red-500 font-semibold'>
                  {emailLoginCheck}
                </div>
                <label
                  htmlFor='emailLogin'
                  className='text-zinc-400 font-semibold'>
                  {t("forms:emailLabel")}
                </label>
                <input
                  name='emailLogin'
                  id='emailLogin'
                  autoComplete='email'
                  className={`${loginInput.loginEmail} form-field px-4 py-2 w-full`}
                  onClick={() => {
                    setLoginInput({
                      ...loginInput,
                      loginEmail: "bg-white",
                    });
                  }}></input>
              </div>
            </div>

            <div className='w-full mb-6'>
              <div className='relative'>
                <label
                  htmlFor='passwordLogin'
                  className='text-zinc-400 font-semibold'>
                  Password
                </label>
                <input
                  name='passwordLogin'
                  id='passwordLogin'
                  type='password'
                  autoComplete='new-password'
                  className={`${loginInput.loginPassword} form-field px-4 py-2 w-full`}
                  onClick={() => {
                    setLoginInput({
                      ...loginInput,
                      loginPassword: "bg-white",
                    });
                  }}></input>
              </div>
            </div>

            <div className='w-full mb-6 flex justify-center'>
              <h1>Already have an account?</h1>
            </div>

            <div className='relative'>
              <div className='absolute top-[-70px] left-4 text-red-500 font-semibold'>
                {emailLoginCheck}
              </div>
            </div>
            <div className='relative'>
              <div className='absolute top-[-50px] left-4 text-red-500 font-semibold'>
                {loginAlert}
              </div>
            </div>

            <button className='btn-main w-full flex items-center justify-center mb-8 shadow-lg'>
              <h1 className='text-white text-lg lg:text-2xl text-center font-semibold'>
                Sign in
              </h1>
            </button>
          </form>
        ) : null}

        <main className='mt-2'>
          <form onSubmit={handleCreateOrder}>
            <article className={formDisplay.defaultShipping}>
              <div className='flex'>
                <img
                  alt={t("altText:verticalDivider")}
                  src='./../assets/icons/divider-vertical.svg'
                  className='w-4 h-10 mr-1'
                />
                <h1 className='mb-4 mt-1 text-2xl font-bold'>
                  {t("utilityPages:finishOrder")}
                </h1>
              </div>
              <div className='w-full mt-2 mb-6'>
                <div className='relative'>
                  <label
                    htmlFor='username'
                    className='text-zinc-400 font-semibold'>
                    {t("forms:usernameLabel")}
                  </label>
                  <input
                    name='username'
                    id='username'
                    onChange={handleInputChange}
                    value={shippingAddress?.username}
                    className={`${inputFormat2.userNameInput} form-field px-4 py-2 w-full`}
                    onClick={() => {
                      setInputFormat2({
                        ...inputFormat2,
                        userNameInput: "bg-white",
                      });
                    }}></input>
                </div>
              </div>

              <div className='w-full mb-6'>
                <div className='relative'>
                  <div className='absolute top-[-28px] left-4 text-red-500 font-semibold'>
                    {emailVerify2}
                  </div>
                  <label
                    htmlFor='email'
                    className='text-zinc-400 font-semibold'>
                    {t("forms:emailLabel")}
                  </label>
                  <input
                    name='email'
                    id='email2'
                    autoComplete='none'
                    onChange={handleInputChange}
                    value={shippingAddress?.email}
                    className={`${inputFormat2.emailInput2} form-field px-4 py-2 w-full`}
                    onClick={() => {
                      setInputFormat2({
                        ...inputFormat2,
                        emailInput2: "bg-white",
                      });
                    }}></input>
                </div>
              </div>

              <div className='w-full mb-6'>
                <div className='relative'>
                  <label
                    htmlFor='street'
                    className='text-zinc-400 font-semibold'>
                    {t("forms:streetLabel")}
                  </label>
                  <input
                    name='street'
                    id='street2'
                    onChange={handleInputChange}
                    value={shippingAddress?.street}
                    className={`${inputFormat2.streetInput2} form-field px-4 py-2 w-full`}
                    onClick={() => {
                      setInputFormat2({
                        ...inputFormat2,
                        streetInput2: "bg-white",
                      });
                    }}></input>
                </div>
              </div>

              <div className='w-full mb-6 flex justify-between'>
                <div className='relative w-[48%]'>
                  <label
                    htmlFor='postalCode'
                    className='text-zinc-400 font-semibold'>
                    {t("forms:postalLabel")}
                  </label>
                  <input
                    name='postalCode'
                    id='postalCode2'
                    onChange={handleInputChange}
                    value={shippingAddress?.postalCode}
                    className={`${inputFormat2.postalCodeInput2} form-field px-4 py-2 w-full`}
                    onClick={() => {
                      setInputFormat2({
                        ...inputFormat2,
                        postalCodeInput2: "bg-white",
                      });
                    }}></input>
                </div>

                <div className='relative w-[48%]'>
                  <label
                    htmlFor='phone'
                    className='text-zinc-400 font-semibold'>
                    {t("forms:phoneLabel")}
                  </label>
                  <input
                    name='phone'
                    id='phone2'
                    onChange={handleInputChange}
                    value={shippingAddress?.phone}
                    className={`${inputFormat2.phoneInput2} form-field px-4 py-2 w-full`}
                    onClick={() => {
                      setInputFormat2({
                        ...inputFormat2,
                        phoneInput2: "bg-white",
                      });
                    }}></input>
                </div>
              </div>

              <div className='w-full mb-6 flex justify-between'>
                <div className='relative w-[48%]'>
                  <label
                    htmlFor='state'
                    className='text-zinc-400 font-semibold'>
                    {t("forms:provinceLabel")}
                  </label>
                  <input
                    name='state'
                    id='state2'
                    onChange={handleInputChange}
                    value={shippingAddress?.state}
                    className={`${inputFormat2.stateInput2} form-field px-4 py-2 w-full`}
                    onClick={() => {
                      setInputFormat2({
                        ...inputFormat2,
                        stateInput2: "bg-white",
                      });
                    }}></input>
                </div>

                <div className='relative w-[48%]'>
                  <label
                    htmlFor='city'
                    className='text-zinc-400 font-semibold'>
                    {t("forms:cityLabel")}
                  </label>
                  <input
                    name='city'
                    id='city2'
                    onChange={handleInputChange}
                    value={shippingAddress?.city}
                    className={`${inputFormat2.cityInput2} form-field px-4 py-2 w-full`}
                    onClick={() => {
                      setInputFormat2({
                        ...inputFormat2,
                        cityInput2: "bg-white",
                      });
                    }}></input>
                </div>
              </div>
            </article>

            <div className='relative'>
              <div className='absolute top-[-26px] left-2 text-red-500 font-semibold'>
                {emailVerify2}
              </div>
            </div>

            <div className='mt-6'>
              <div className='relative'>
                <div className='absolute bottom-[12px] left-[8px] text-sm text-red-500 font-semibold'>
                  {passwordLength}
                </div>
              </div>

              <div className='flex flex-col items-center bg-white rounded-lg p-8 drop-shadow'>
                <h3 className='text-2xl text-gray-600 font-bold mb-2'>
                  {t("checkoutPage:timeslotTitle")}
                </h3>
                {deliverySlots.length > 0 ? (
                  deliverySlots.map((slot, ind) => {
                    const uniqueId = `delivery-slot-${ind}`;
                    return (
                      <div
                        key={ind}
                        className='p-4 bg-white rounded-md drop-shadow my-2 w-full'>
                        <label
                          htmlFor={uniqueId}
                          className='ml-2 text-xl font-semibold text-orange-600'>
                          {moment(slot.date).format("DD/MM")}{" "}
                          <span className='text-sm text-gray-500'>
                            {slot.time}
                          </span>
                        </label>
                      </div>
                    );
                  })
                ) : (
                  <div className='text-gray-500 text-center'>
                    {t("checkoutPage:deliveryExplainer")}
                  </div>
                )}
                <div
                  onClick={handleSetTimeslots}
                  className='btn-secondary flex justify-center items-center mt-8 w-full sm:w-auto p-4 md:px-6 lg:px-8 text-center hover:scale-105 transition-transform duration-200'>
                  <FaTruck className='w-6 h-6 md:w-8 md:h-8 mr-2 md:mr-4' />
                  {t("checkoutPage:editTimeslotButton")}
                </div>
              </div>
              <hr className='my-8 w-full'></hr>

              <h3 className='flex justify-end mb-8 text-3xl text-gray-800 font-bold'>
                <h4 className='mt-1.5 mr-3 text-xl text-gray-500'>
                  {t("admin:grandTotalHeader")}{" "}
                </h4>
                {grandTotal.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                kr.
              </h3>
              <h1 className='ml-2 mb-4 h-5 text-red-500 font-semibold'>
                {formAlert}
              </h1>
              <div className='flex justify-center'>
                <button
                  disabled={loading}
                  className={`btn-main w-full flex items-center justify-center mb-2 ${
                    loading ? "opacity-50 cursor-not-allowed" : ""
                  }`}>
                  <FaRegCreditCard className='w-8 h-8' />
                  <div className='h-full border-2 border-l border-white mx-4'></div>
                  {loading ? (
                    <LoadingButtonWide />
                  ) : (
                    <h1 className='text-white text-lg lg:text-2xl text-center font-semibold'>
                      {t("utilityPages:bankTransfer")}
                    </h1>
                  )}
                </button>
              </div>
            </div>
          </form>
          <KlarnaPayment
            shippingAddress={shippingAddress}
            orderItems={searchTerm?.shoppingCart}
            deliverySlots={searchTerm?.deliverySlots}
            totalAmount={grandTotal}
            prepareOrderData={prepareOrderData}
            navigateToConfirmation={navigateToConfirmation}
          />
        </main>
      </div>
    </div>
  );
}

export default CatalogueAddress;
