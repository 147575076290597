import React, { useState, useContext, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { getReq } from "../../configs/ApiUrl.js";
import { useTranslation } from "react-i18next";
import { HiMagnifyingGlass } from "react-icons/hi2";
import { AiOutlineInfoCircle } from "react-icons/ai";
import AlwaysTop from "./util/AlwaysTop.js";
import AddFavouriteProduct from "./util/AddFavouriteProduct.js";
import HelpButton from "../HelpButton.js";
import PageHeader from "../PageHeader.js";
import PageFooter from "../PageFooter.js";
import ProductCard from "../../screens/ProductCard.js";
import { LoadingSpinner } from "./util/LoadingSpinner.js";
import { FurnitureSearch } from "../../App.js";

function ShoppingHome() {
  const { t } = useTranslation(["homepage", "productTypes"]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [approvedProducts, setApprovedProducts] = useState([]);
  const [favouriteProducts, setFavouriteProducts] = useState([]);
  const [furnitureStockCount, setFurnitureStockCount] = useState([]);
  const [searchTerm, setSearchTerm] = useContext(FurnitureSearch);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentFurnitureIndex, setCurrentFurnitureIndex] = useState(0);
  const userId = localStorage.getItem("userId");

  const slides = useMemo(
    () => [
      {
        id: 1,
        url: "./../assets/images/bedroom.jpg",
        title: t("productTypes:bedroom"),
        selection: "bedroom",
        text: "text-white border-b-4 border-cromboOrangeFade bg-black/50 px-2",
      },
      {
        id: 2,
        url: "./../assets/images/living-room.jpg",
        title: t("productTypes:livingRoom"),
        selection: "living room",
        text: "text-white border-b-4 border-cromboOrangeFade bg-black/50 px-2",
      },
      {
        id: 3,
        url: "./../assets/images/kitchen.jpg",
        title: t("productTypes:kitchen"),
        selection: "kitchen",
        text: "text-white border-b-4 border-cromboOrangeFade bg-black/50 px-2",
      },
      {
        id: 4,
        url: "./../assets/images/bathroom.jpeg",
        title: t("productTypes:bathroom"),
        selection: "bathroom",
        text: "text-white border-b-4 border-cromboOrangeFade bg-black/50 px-2",
      },
      {
        id: 5,
        url: "./../assets/images/foyer.jpeg",
        title: t("productTypes:foyer"),
        selection: "foyer",
        text: "text-white border-b-4 border-cromboOrangeFade bg-black/50 px-2",
      },
      {
        id: 6,
        url: "./../assets/images/office.jpg",
        title: t("productTypes:office"),
        selection: "office",
        text: "text-white border-b-4 border-cromboOrangeFade bg-black/50 px-2",
      },
      {
        id: 7,
        url: "./../assets/images/patio.jpg",
        title: t("productTypes:balcony"),
        selection: "balcony",
        text: "text-white border-b-4 border-cromboOrangeFade bg-black/50 px-2",
      },
    ],
    [t]
  );

  const furnitureSlides = useMemo(
    () => [
      {
        id: 1,
        url: "./../assets/images/sofa.jpg",
        title: t("productTypes:sofa"),
        selection: "sofa",
        displayName: t("productTypes:sofa"),
        text: "text-white bg-black/50 px-2 mt-2",
      },
      {
        id: 2,
        url: "./../assets/images/wardrobe.jpg",
        title: t("productTypes:wardrobe"),
        selection: "wardrobe",
        displayName: t("productTypes:wardrobe"),
        text: "text-white bg-black/50 px-2 mt-2",
      },
      {
        id: 3,
        url: "./../assets/images/bed.jpg",
        title: t("productTypes:bed"),
        selection: "bed",
        displayName: t("productTypes:bed"),
        text: "text-white bg-black/50 px-2 mt-2",
      },
      {
        id: 4,
        url: "./../assets/images/chair.jpg",
        title: t("productTypes:chair"),
        selection: "chair",
        displayName: t("productTypes:chair"),
        text: "text-white bg-black/50 px-2 mt-2",
      },
      {
        id: 5,
        url: "./../assets/images/little-things.jpg",
        title: t("productTypes:littleThings"),
        selection: "little-things",
        displayName: t("productTypes:littleThings"),
        text: "text-white bg-black/50 px-2 mt-2",
      },
      {
        id: 6,
        url: "./../assets/images/appliances.webp",
        title: t("productTypes:appliance"),
        selection: "appliance",
        displayName: t("productTypes:appliance"),
        text: "text-white bg-black/50 px-2 mt-2",
      },
      {
        id: 7,
        url: "./../assets/images/table.jpg",
        title: t("productTypes:table"),
        selection: "table",
        displayName: t("productTypes:table"),
        text: "text-white bg-black/50 px-2 mt-2",
      },
      {
        id: 8,
        url: "./../assets/images/dresser.jpg",
        title: t("productTypes:dresser"),
        selection: "dresser",
        displayName: t("productTypes:dresser"),
        text: "text-white bg-black/50 px-2 mt-2",
      },
    ],
    [t]
  );

  useEffect(() => {
    const getAllApprovedProducts = async () => {
      try {
        setLoading(true);
        const allProductsResp = await getReq("/product/all");

        if (allProductsResp?.allProducts) {
          const filteredProducts = allProductsResp.allProducts.filter(
            (product) =>
              product.status === "approved" || product.status === "preApproved"
          );
          setApprovedProducts(filteredProducts);
        } else {
          setApprovedProducts([]);
        }

        // Fetch user's favourites
        const favouriteResp = await getReq(`/user/${userId}/get-favourite`);
        if (favouriteResp && favouriteResp.favouriteProducts) {
          setFavouriteProducts(favouriteResp.favouriteProducts);
        }
      } catch (error) {
        console.error("Error fetching approved products:", error);
      } finally {
        setLoading(false);
      }
    };

    getAllApprovedProducts();
  }, [userId]);

  useEffect(() => {
    const calculatedFurnitureStockCount = furnitureSlides.map((slide) => {
      const count = approvedProducts.filter(
        (product) =>
          product.category.toLowerCase() === slide.selection.toLowerCase()
      ).length;
      return { ...slide, stockCount: count };
    });
    setFurnitureStockCount(calculatedFurnitureStockCount);
  }, [approvedProducts, furnitureSlides]);

  const handleFavourite = (i, productId) => {
    const isFavourite = favouriteProducts.includes(productId);
    if (isFavourite) {
      setFavouriteProducts(favourites => favourites.filter(id => id !== productId));
    } else {
      setFavouriteProducts([...favouriteProducts, productId]);
    }
  };

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    const searchInput = e.target.search; // Ensure this matches the name attribute of the input field

    if (!searchInput) {
      console.error("Search input field not found");
      return;
    }

    const searchQuery = searchInput.value;

    if (!searchQuery) {
      console.error("Search query is empty");
      return;
    }
    try {
      setLoading(true);
      const response = await getReq(`/product/search/${searchQuery}`);

      if (response?.products && response.products.length > 0) {
        setSearchTerm({
          ...searchTerm,
          searchFilter: response.products,
          displayName: `Search results for "${searchQuery}"`,
          productDisplayMain: "visible",
          productDisplayZoom: "hidden",
          productArrow: "visible",
          generalArrow: "hidden",
        });
      } else {
        setSearchTerm({
          ...searchTerm,
          searchFilter: [],
          displayName: `No results found for "${searchQuery}"`,
          productDisplayMain: "visible",
          productDisplayZoom: "hidden",
          productArrow: "visible",
          generalArrow: "hidden",
        });
      }
    } catch (error) {
      console.error("Error searching for products:", error);
      setSearchTerm({
        ...searchTerm,
        searchFilter: [],
        displayName: `Error occurred during search for "${searchQuery}"`,
        productDisplayMain: "visible",
        productDisplayZoom: "hidden",
        productArrow: "visible",
        generalArrow: "hidden",
      });
    } finally {
      setLoading(false);
      navigate(`/search-results/${searchQuery}`);
    }
  };

  const handleProductSelect = (e) => {
    const productId = e.currentTarget.getAttribute("data-product-id");
    const displayName = e.currentTarget.getAttribute("data-product-name");

    if (!productId || !displayName) {
      console.error("Product ID or Display Name is missing");
      return;
    }

    setSearchTerm({
      ...searchTerm,
      searchFilter: approvedProducts.filter((x) => x._id === productId),
      displayName,
      productDisplayMain: "hidden",
      productDisplayZoom: "visible",
      productArrow: "hidden",
      generalArrow: "visible",
      arrowNavigation: "/shopping-home",
      searchID: productId,
    });
    navigate(`/catalogue-product/${productId}`);
  };

  useEffect(() => {
    AlwaysTop();
  }, []);

  const prevSlide = () =>
    setCurrentIndex((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  const nextSlide = () =>
    setCurrentIndex((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
  const prevFurniture = () =>
    setCurrentFurnitureIndex((prev) =>
      prev === 0 ? furnitureStockCount.length - 1 : prev - 1
    );
  const nextFurniture = () =>
    setCurrentFurnitureIndex((prev) =>
      prev === furnitureStockCount.length - 1 ? 0 : prev + 1
    );

    const handleCategoryClick = () => {
      const selectedCategory =
        furnitureStockCount[currentFurnitureIndex]?.selection;
      const filteredProducts = approvedProducts.filter(
        (x) => x.category.toLowerCase() === selectedCategory.toLowerCase()
      );
      setSearchTerm({
        ...searchTerm,
        searchFilter: filteredProducts,
        displayName: furnitureStockCount[currentFurnitureIndex]?.displayName,
        productDisplayMain: "visible",
        productDisplayZoom: "hidden",
        productArrow: "visible",
        generalArrow: "hidden",
      });
    
      navigate(`/category-search/${selectedCategory}`);
    };    

  return (
    <div className="flex flex-col items-center mt-6 bg-white">
      <PageHeader className="mb-10" />
      <div className="max-w-md w-full">
        <div className="relative w-full">
          <img src="./../assets/images/bed.jpg" className="w-full h-auto" />
          <div className="relative w-full">
            <div className="relative bg-white rounded-t-2xl p-4 -mt-12">
              <h1 className="text-3xl font-bold">{t("tagline1")}<br />{t("tagline2")}</h1>
            </div>

            <article className="absolute left-0 right-0 -bottom-12 w-11/12 w-full">
              <form
                className="flex relative bg-white rounded-xl"
                onSubmit={handleSearchSubmit}
              >
                <input
                  className="border-b-[2px] border-0 border-stone-400 w-full h-14 pl-4 focus:border-cromboOrange focus:ring-0"
                  placeholder={t("searchPlace")}
                  name="search"
                />
                <button
                  type="submit"
                  className="h-14 w-14 bg-cromboOrange flex items-center justify-center"
                >
                  <HiMagnifyingGlass
                    color="white"
                    className="w-7 h-8"
                  />
                </button>
              </form>
            </article>
          </div>
        </div>
        <main className="mt-24">
          <div className="ml-2 my-6 flex items-center">
            <img
              alt={t("altText:verticalDivider")}
              src="./../assets/icons/divider-vertical.svg"
              className="w-6 h-8"
            />
            <h2 className="font-bold text-2xl">{t("categoriesHeader")}</h2>
          </div>

          <h3 className="text-stone-600 font-bold text-xl pl-6">
            {t("roomSubheader")}
          </h3>
          <h4 className="text-stone-400 pl-6 text-sm">{t("roomDesc")}</h4>
          <article className="max-w-[450px] h-[425px] w-full m-auto py-4 px-4 relative">
            <Link
              to={`/room-search/${slides[currentIndex].selection}`}
              onClick={() => {
                setSearchTerm({
                  ...searchTerm,
                  searchFilter: database.filter((x) =>
                    x.room.includes(slides[currentIndex].selection)
                  ),
                  displayName: slides[currentIndex].title,
                  productDisplayMain: "visible",
                  productDisplayZoom: "hidden",
                  productArrow: "visible",
                  generalArrow: "hidden",
                });
              }}
            >
              <div
                style={{
                  backgroundImage: `url(${slides[currentIndex].url})`,
                }}
                className="w-full h-96 rounded-2xl bg-center bg-cover duration-500"
              ></div>
            </Link>
            <div
              className="absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-3 bg-black/20 text-white cursor-pointer"
              onClick={prevSlide}
            >
              <MdChevronLeft size={50} />
            </div>
            <div
              className="absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-3 bg-black/20 text-white cursor-pointer"
              onClick={nextSlide}
            >
              <MdChevronRight size={50} />
            </div>
            <div
              className={`${slides[currentIndex].text} absolute top-6 ml-3 font-semibold`}
            >
              {slides[currentIndex].title}
            </div>
          </article>

          <div className="flex justify-center mb-6">
            <img
              alt={t("altText:horizontalDivider")}
              src="./../assets/icons/divider-horizontal.svg"
              className="w-80"
            />
          </div>

          <div>
            <h3 className="text-stone-600 font-bold text-xl pl-6">
              {t("furnitureSubheader")}
            </h3>
            <h4 className="text-stone-400 pl-6 text-sm">
              {t("furnitureDesc")}
            </h4>
          </div>
          <article className="max-w-[450px] h-[280px] w-full m-auto pt-4 px-4 relative cursor-pointer">
            <div onClick={handleCategoryClick}>
              <div
                style={{
                  backgroundImage: `url(${furnitureSlides[currentFurnitureIndex].url})`,
                }}
                className="w-full h-60 rounded-2xl bg-center bg-cover bg-no-repeat duration-500 relative"
              >
                <div className="bg-black/50 border-b-8 rounded-b-2xl border-cromboOrangeFade p-4 text-white absolute inset-x-0 bottom-0 text-center text-xl font-bold">
                  {furnitureStockCount[currentFurnitureIndex]?.displayName}
                  {furnitureStockCount[currentFurnitureIndex]?.stockCount >
                    0 && (
                    <span className="ml-2 text-xs text-gray-300">
                      {furnitureStockCount[currentFurnitureIndex]?.stockCount}{" "}
                      {furnitureStockCount[currentFurnitureIndex]?.stockCount >
                      1
                        ? t("itemsInStockPlural")
                        : t("itemsInStock")}{" "}
                      {t("inStock")}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div
              className="absolute top-[50%] -translate-x-0 translate-y-[-65%] left-5 text-2xl rounded-full p-3 bg-black/20 text-white cursor-pointer"
              onClick={prevFurniture}
            >
              <MdChevronLeft size={50} />
            </div>
            <div
              className="absolute top-[50%] -translate-x-0 translate-y-[-65%] right-5 text-2xl rounded-full p-3 bg-black/20 text-white cursor-pointer"
              onClick={nextFurniture}
            >
              <MdChevronRight size={50} />
            </div>
          </article>

          <article>
            <div className="ml-2 mb-8 mt-12 flex items-center">
              <img
                alt={t("altText:verticalDivider")}
                src="./../assets/icons/divider-vertical.svg"
                className="w-6 h-8"
              />
              <h3 className="font-bold text-2xl">{t("productsHeader")}</h3>
            </div>
            <div className="grid grid-cols-2 gap-0 mt-4">
              {approvedProducts.length > 0 && !loading
                ? approvedProducts.map((product, i) => (
                    <div key={i} className="relative">
                      <div className="absolute z-10 left-2 top-1">
                        <AddFavouriteProduct
                          userId={userId}
                          productId={product._id}
                          isFavourited={favouriteProducts.includes(product._id)}
                          onSuccess={() => handleFavourite(i, product._id)}
                        />
                      </div>
                      <ProductCard
                        key={product._id}
                        id={product._id}
                        name={product.title}
                        price={product.price}
                        url={product.mainImage || product.imageNames[0]}
                        moving={product.deliveryPrice}
                        condition={product.condition}
                        verified={product.isQualityVerified}
                        onProductSelect={handleProductSelect}
                      />
                    </div>
                  ))
                : !loading && (
                    <div className="w-[450px] flex items-center mt-20 mb-20 justify-center">
                      <AiOutlineInfoCircle
                        size={20}
                        className="text-gray-500 mr-2"
                      />
                      <span className="text-stone-400 font-bold">
                        {t("homepage:noProduct")}
                      </span>
                    </div>
                  )}
            </div>
            {loading && (
              <div className="w-[450px] flex justify-center items-center">
                <LoadingSpinner />
              </div>
            )}
          </article>
          <HelpButton />
        </main>
        <PageFooter />
      </div>
    </div>
  );
}

export default ShoppingHome;
