import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { postReq } from "../../configs/ApiUrl.js";
import { FurnitureSearch } from "../../App.js";
import { useTranslation } from "react-i18next";
import { message } from "antd";
import { ProductContext } from "../pages/util/ProductContext";
import NewProductForm from "../NewProductForm";
import { validateProductForm } from "../pages/util/AddProductUtils.js";

const calculateCompletedSteps = (product) => {
  let completed = 0;
  if (product.imageNames.length > 0) completed++;
  if (product.title.trim() !== "") completed++;
  if (product.price > 0) completed++;
  if (product.condition.trim() !== "") completed++;
  if (product.model.trim() !== "") completed++;
  if (product.category.trim() !== "") completed++;
  if (product.rooms.length > 0) completed++;
  if (
    product.dimension.width > 0 &&
    product.dimension.height > 0 &&
    product.dimension.depth > 0
  )
    completed++;
  if (product.description.trim() !== "") completed++;
  return completed;
};

function AdminAddProduct() {
  const { t } = useTranslation();
  const posterId = localStorage.getItem("userId");
  const userData = JSON.parse(localStorage.getItem("user"));
  const subadminEmail = userData ? userData.email : "";
  const [searchTerm, setSearchTerm] = useContext(FurnitureSearch);
  const navigate = useNavigate();
  const [errors, setErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { setProduct } = useContext(ProductContext);
  const [product, setProductState] = useState({
    posterId: posterId || "",
    imageNames: [],
    category: "",
    rooms: [],
    price: 0,
    condition: "",
    dimension: { width: 0, height: 0, depth: 0 },
    model: "",
    location: { street: "", postalCode: "", phone: "", state: "", city: "" },
    title: "",
    description: "",
    subadminEmail: subadminEmail,
  });

  const handleCreateProduct = async (e) => {
    e.preventDefault();
    setErrors("");
    if (!validateProductForm(product)) {
      return setErrors("Please fill in the missing fields");
    }

    const formData = new FormData();
    formData.append("posterId", product?.posterId);
    formData.append("title", product?.title);
    formData.append("price", product?.price);
    formData.append("color", product?.color);
    formData.append("category", product?.category);
    formData.append("condition", product?.condition);
    product?.rooms.forEach((room, index) => {
      formData.append(`rooms[${index}]`, room);
    });
    formData.append("model", product?.model);
    formData.append("description", product?.description);
    formData.append("dimension", JSON.stringify(product?.dimension));
    formData.append("location", JSON.stringify(product?.location));
    formData.append("subadminEmail", product?.subadminEmail);
    product.imageNames.forEach((image) => {
      formData.append("imageNames", image?.originFileObj);
    });

    try {
      setIsLoading(true);
      const productResp = await postReq("/product/add", formData);
      if (productResp?.success) {
        message.success(t("uploadSuccess"), 2);
        setProduct(productResp.product);
        setSearchTerm({ ...searchTerm, adminSearch: [productResp.product] });
        navigate("/admin-product-view", {
          state: { product: productResp.product },
        });
      } else {
        setErrors("Product creation failed due to server error");
      }
    } catch (error) {
      setErrors("Product creation failed due to server error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <NewProductForm
      errors={errors}
      setErrors={setErrors}
      product={product}
      setProductState={setProductState}
      handleCreateProduct={handleCreateProduct}
      isLoading={isLoading}
      completedSteps={calculateCompletedSteps(product)}
      additionalFields={null}
      headerLink='/admin-menu'
    />
  );
}

export default AdminAddProduct;