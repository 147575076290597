import React, { useEffect, useState } from "react";
import { getReq, putReq } from "../../configs/ApiUrl";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { HiOutlineArrowLongLeft, HiOutlineTrash } from "react-icons/hi2";
import moment from "moment";
import { message } from "antd";
import locationMark from "../../images/locationMark.svg";
import { LoadingSpinner } from "./util/LoadingSpinner";
import { FaCheck } from "react-icons/fa";
import PageSubheader from "../PageSubheader.js";
import AlwaysTop from "./util/AlwaysTop.js";

function DeliveryStatus() {
  const { t } = useTranslation([
    "admin",
    "tables",
    "altText",
    "forms",
    "productPage",
  ]);
  const [allOrders, setAllOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updatedProduct, setUpdatedProduct] = useState({});
  const [userRole, setUserRole] = useState("");
  const navigate = useNavigate();

  const fetchUserRole = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const roleResp = await getReq(`/user/${userId}`);
      if (roleResp?.success) {
        setUserRole(roleResp.role);
      } else {
        console.error("Failed to fetch user role:", roleResp.message);
      }
    } catch (error) {
      console.error("Error fetching user role:", error.message);
    }
  };

  const getAllOrders = async () => {
    try {
      setLoading(true);
      const ordersResp = await getReq(`/order/all`);
      if (ordersResp?.success) {
        const filteredOrders = ordersResp.allOrders.filter((order) => {
          if (userRole === "admin") {
            return order.orderStatus === "shipped";
          } else if (userRole === "subadmin") {
            const user = JSON.parse(localStorage.getItem("user"));
            return (
              order.orderStatus === "shipped" &&
              order.subadminEmail === user?.email
            );
          }
          return false;
        });

        const sortedOrders = filteredOrders.sort(
          (a, b) => new Date(a.orderDate) - new Date(b.orderDate)
        );

        setAllOrders(sortedOrders);
      } else {
        console.error("Failed to fetch orders");
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setLoading(false);
    }
  };

  const updateOrderStatus = async (orderId) => {
    try {
      const response = await putReq(`/order/${orderId}/update-status`, {
        status: "delivered",
      });
      if (response.success) {
        setUpdatedProduct({ _id: orderId, status: "delivered" });
        await getAllOrders(); // Refresh orders list after status update
      } else {
        console.error("Failed to update order status");
      }
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  };

  const handleDeclinePayment = async (product, order) => {
    try {
      setLoading(true);
      setUpdatedProduct({});

      // Determine the new status based on subadminEmail
      const newStatus = order?.subadminEmail ? "approved" : "preapproved";

      const requestData = {
        order_id: order?._id,
        id: product?._id,
        status: newStatus,
        customerId: order?.customerId,
        deliverySlots: order.deliverySlots,
      };

      const declineResp = await putReq(`/product/decline/payment`, requestData);

      if (declineResp?.success) {
        // Make a call to update the order status to "cancelled"
        const orderUpdateResp = await putReq(
          `/order/${order._id}/update-status`,
          { status: "cancelled" }
        );

        if (orderUpdateResp?.success) {
          message.warning("Order Cancelled", 2);
          setUpdatedProduct(declineResp?.product);

          setAllOrders((prevOrders) =>
            prevOrders.map((o) =>
              o._id === order._id
                ? {
                    ...o,
                    orderStatus: "cancelled",
                    orderItems: o.orderItems.map((i) =>
                      i.product._id === product._id
                        ? { ...i, product: { ...i.product, status: newStatus } }
                        : i
                    ),
                  }
                : o
            )
          );
        } else {
          message.error("Order Status Update Failed", 1);
        }
        setLoading(false);
      } else {
        message.error("Decline Payment Failed", 1);
        setUpdatedProduct({});
        setLoading(false);
      }
    } catch (error) {
      console.error("Error in handleDeclinePayment:", error);
      message.error("Payment server error", 1);
      setUpdatedProduct({});
      setLoading(false);
    }
  };

  useEffect(() => {
    AlwaysTop();
    fetchUserRole(); // Fetch user role on component mount
  }, []);

  useEffect(() => {
    if (userRole) {
      getAllOrders(); // Fetch orders after the role is determined
    }
  }, [userRole, updatedProduct?._id]);

  return (
    <div className="flex flex-col items-center">
      {userRole === "admin" ? (
        <header className="w-full flex items-center justify-between mb-10 bg-white drop-shadow">
          <Link to="/admin-menu" className="flex inline-flex back-btn mx-4">
            <HiOutlineArrowLongLeft size={30} color="white" />
          </Link>
          <article className="w-full flex-col items-center justify-center mr-16 my-6">
            <h1 className="text-3xl font-bold flex-grow text-center">
              {t("admin:deliveryStatusButton")}
            </h1>
            <p className="mt-1 text-sm font-semibold text-center text-gray-500">
              {t("admin:deliveryStatusExplainer")}
            </p>
          </article>
        </header>
      ) : (
        <PageSubheader
          backLink="/subadmin-menu"
          title={t("admin:deliveryStatusButton")}
          description={t("admin:deliveryStatusExplainer")}
        />
      )}

      <main className="flex flex-col justify-center mb-10 bg-white p-2 w-full drop-shadow">
        <div className="overflow-x-auto">
          {loading ? (
            <LoadingSpinner />
          ) : allOrders.length > 0 ? (
            <table className="table-auto w-full border-collapse rounded-t-xl">
              <thead>
                <tr>
                  <th
                    className="px-24 py-2 bg-none border-b-4 border-cromboOrangeFade text-black font-semibold"
                    scope="col"
                  >
                    {t("tables:image")}
                  </th>
                  <th
                    className="px-4 border-b-4 border-cromboOrangeFade text-black font-semibold"
                    scope="col"
                  >
                    {t("admin:grandTotalHeader")}
                  </th>
                  <th
                    className="px-4 border-b-4 border-cromboOrangeFade text-black font-semibold"
                    scope="col"
                  >
                    {t("tables:deliverySlots")}
                  </th>
                  <th
                    className="px-4 border-b-4 border-cromboOrangeFade text-black font-semibold"
                    scope="col"
                  >
                    {t("tables:address")}
                  </th>
                  <th
                    className="px-4 border-b-4 border-cromboOrangeFade text-black font-semibold"
                    scope="col"
                  >
                    {t("forms:phoneLabel")}
                  </th>
                  <th
                    className="px-4 border-b-4 border-cromboOrangeFade text-black font-semibold"
                    scope="col"
                  >
                    {t("admin:actionHeader")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {allOrders.map((order, orderIndex) =>
                  order.orderItems
                    ?.filter((item) => item.product.status === "sold")
                    .map((item, itemIndex) => (
                      <tr
                        key={`${orderIndex}-${itemIndex}`}
                        className="hover:bg-background"
                      >
                        <td className="relative border-b-4 border-cromboOrangeFade">
                          <div className="flex flex-col items-center">
                            {item?.product?.imageNames &&
                            item?.product?.imageNames[0] ? (
                              <img
                                className="w-full max-w-sm h-auto mx-auto"
                                src={item?.product.imageNames[0]}
                                alt={t("altText:firstImage")}
                              />
                            ) : (
                              <div>No image available</div>
                            )}
                            <div className="table-overlay">
                              <span className="truncate text-sm">
                                {item?.product?.title?.length > 20
                                  ? item?.product?.title.substring(0, 15) +
                                    "..."
                                  : item?.product?.title}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className="flex-col justify-start px-16 py-4 bg-background border-b-4 border-cromboOrangeFade text-slate-500">
                          <div className="flex items-center justify-between mb-1">
                            <div className="text-sm mr-2 mb-0.5 whitespace-nowrap">
                              {t("admin:price")}
                            </div>
                            <div className="text-xl font-bold whitespace-nowrap">
                              {item.product?.price ?? 0} kr.
                            </div>
                          </div>
                          <div className="flex items-center justify-between mb-1">
                            <div className="text-sm mr-2 -mb-1 whitespace-nowrap">
                              {t("admin:deliveryPriceLabel")}
                            </div>
                            <div className="text-xl font-bold whitespace-nowrap">
                              {item.product?.deliveryPrice ?? 0} kr.
                            </div>
                          </div>

                          <div className="whitespace-pre-wrap mb-2">
                            {Array.isArray(item.product?.addOns) &&
                            item.product?.addOns.length > 0 ? (
                              item.product?.addOns.map((addon, index) => (
                                <div
                                  key={index}
                                  className="flex items-center justify-between mb-1"
                                >
                                  <div className="text-sm mr-2 -mb-1 whitespace-nowrap">
                                    {addon.item}
                                  </div>
                                  <div className="text-xl font-bold whitespace-nowrap">
                                    {addon.price ?? 0} kr.
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div className="flex items-center justify-between mb-1">
                                <div className="text-sm mr-2 -mb-1 whitespace-nowrap">
                                  {t("admin:noAddons")}
                                </div>
                                <div className="text-xl font-bold whitespace-nowrap">
                                  0 kr.
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="flex items-center justify-between mb-1">
                            <div className="text-sm mr-2 mb-0.5 whitespace-nowrap">
                              {t("productPage:bonusDiscountLabel")}
                            </div>
                            <div className="text-xl font-bold whitespace-nowrap">
                              {item.product?.bonusDiscount ?? 0} kr.
                            </div>
                          </div>
                          <div className="flex items-center justify-between mb-1">
                            <div className="text-sm mr-2 mb-0.5 whitespace-nowrap">
                              {t("admin:discount")}
                            </div>
                            <div className="text-xl font-bold whitespace-nowrap">
                              {order.orderItems?.[0]?.extraTotal ?? 0} kr.
                            </div>
                          </div>
                          <div className="flex items-center justify-between mb-1">
                            <div className="text-sm mr-2 font-bold text-slate-800 mb-0.5 whitespace-nowrap">
                              {t("admin:grandTotalHeader")}
                            </div>
                            <div className="text-xl font-bold text-cromboOrange whitespace-nowrap">
                              {order.orderItems?.[0]?.grandTotal ?? "N/A"} kr.
                            </div>
                          </div>
                        </td>

                        <td className="px-16 py-2 bg-background border-b-cromboOrangeFade border-4 border-r-2 border-white text-slate-500">
                          <div className="p-4 bg-white rounded-md drop-shadow">
                            <hr className="px-24 my-2 w-full"></hr>
                            {order.deliverySlots.map((slot, ind) => {
                              const dateTimeValue = `${moment(slot.date).format(
                                "DD/MM"
                              )} ${slot.time}`;
                              const uniqueId = `delivery-slot-${orderIndex}-${itemIndex}-${ind}`;
                              return (
                                <div
                                  key={ind}
                                  className="flex items-center mb-2"
                                >
                                  <label
                                    htmlFor={uniqueId}
                                    className="ml-2 text-xl font-semibold text-orange-600"
                                  >
                                    {moment(slot.date).format("DD/MM")}{" "}
                                    <span className="text-sm text-gray-500">
                                      {slot.time}
                                    </span>
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        </td>

                        <td
                          className="px-12 py-2 bg-background border-b-cromboOrangeFade border-4 border-r-2 border-white text-slate-500"
                          style={{ width: 200, whiteSpace: "normal" }}
                        >
                          <div className="flex flex-col items-center">
                            <img
                              className="w-8 h-8 mb-2"
                              src={locationMark}
                              alt={t("altText:location")}
                            />
                            <span className="text-center">{`${order?.shippingAddress?.street}, ${order?.shippingAddress?.state}, ${order?.shippingAddress?.city}.`}</span>
                          </div>
                        </td>

                        <td className="px-4 py-2 bg-background border-b-cromboOrangeFade border-4 border-r-2 border-white text-slate-500">
                          <div className="w-40 py-2 flex justify-center items-start">
                            <span className="py-1">
                              {order?.shippingAddress?.phone}
                            </span>
                          </div>
                        </td>

                        <td className="px-16 py-2 bg-background border-b-cromboOrangeFade border-4 border-r-2 border-white text-slate-500">
                          <button
                            className="flex items-center justify-center p-4 rounded-lg bg-white border-2 border-white text-cromboOrange hover:text-cromboOrangeFade cursor-pointer drop-shadow"
                            onClick={() => updateOrderStatus(order._id)}
                          >
                            <FaCheck className="w-8 h-8" />
                            <h4 className="ml-2 text-sm font-bold">
                              {t("admin:confirmDeliveryButton")}
                            </h4>
                          </button>
                          <button
                            className="flex items-center justify-center p-4 mt-4 rounded-lg bg-white border-2 border-white text-red-600 hover:text-red-700 cursor-pointer drop-shadow"
                            onClick={() =>
                              handleDeclinePayment(item?.product, order)
                            }
                          >
                            <HiOutlineTrash className="h-10 w-10" />
                            <h4 className="ml-2 text-sm font-bold">
                              {t("admin:cancelOrderButton")}
                            </h4>
                          </button>
                        </td>
                      </tr>
                    ))
                )}
              </tbody>
            </table>
          ) : (
            <div className='flex items-center justify-center w-full h-96 bg-white'>
              <h1 className="text-xl font-bold text-center text-gray-500">
                {t("admin:noOrders")}
              </h1>
            </div>
          )}
        </div>
      </main>
    </div>
  );
}

export default DeliveryStatus;
