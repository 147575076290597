import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { getReq, postReq } from "../../configs/ApiUrl";
import axios from "axios";
import { useTranslation } from "react-i18next";
import AlwaysTop from "./util/AlwaysTop";

// This page provides a form where new users may sign up to 24Mobler. Users are also presented with the option of signing using
// other, external, accounts. Registered users, who have accessed this page in error, are also provided with a link to the sign in
// page.

function WelcomeSignup() {
  // This navigate function allows the user both to complete the form and to navigate to the 'Sign In' page (WelcomeLogin.js
  // component) upon clicking the orange 'Sign Up' button below. This function, and its application below, derives from an example
  // given in a Youtube video by Caleb Curry: Caleb Curry, "Redirect with useNavigate Hook - React Tutorial 24",  Youtube (2022),
  // last viewed 22 October 2023: https://www.youtube.com/watch?v=NvNJtZaDt8E

  const { t } = useTranslation(["forms", "altText"]);

  const navigateLogin = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  // These variables are employed with various useState hooks to alert the user of any incomplete or incorrectly completed parts of
  // the form. They work by highlighting problem fields, either by changing input backgrounds to pale red with the use of explanatory
  // placeholder text, or through the use of red explanatory text. The initial values of these variable are for their default (non-
  // alert) state.

  let nameInputColor = "bg-white";
  let emailInputColor = "bg-white";
  let passwordInputColor = "bg-white";
  let passwordConfirmInputColor = "bg-white";

  let namePlace = "";
  let emailPlace = "";
  let passwordPlace = "";
  let passwordConfirmPlace = "";

  let passwordAlert = "";
  let colorAlert = "text-black";

  let termAlert = "";

  let emailAlert = "";

  let lengthAlert = "";

  // These useState functions alert the user of any incomplete or incorrectly completed parts of the user registration form.

  // These first five useState functions alert the user if any of the five input fields are incomplete: Name, Email address,
  // Create Password, Confirm Password and the checkbox to agree to terms and conditions.

  const [nameState, setNameState] = useState({
    nameBackground: nameInputColor,
    nameHolder: t("usernamePlace"),
  });

  const [emailState, setEmailState] = useState({
    emailBackground: emailInputColor,
    emailHolder: t("emailLabel"),
  });

  const [passwordState, setPasswordState] = useState({
    passwordBackground: passwordInputColor,
    passwordHolder: t("passwordLabel"),
  });

  const [passwordConfirmState, setPasswordConfirmState] = useState({
    passwordConfirmBackground: passwordConfirmInputColor,
    passwordConfirmHolder: t("passwordConfirm"),
  });

  const [termState, setTermState] = useState({
    termText: termAlert,
  });

  // This function is used to verify that the user has correctly provided a password and a matching confirmation password.

  const [passwordMatchState, setPasswordMatchState] = useState({
    passwordMatchText: passwordAlert,
    passwordMatchColor: colorAlert,
  });

  // This function verifies that the user email address contains an @ sign.

  const [emailConfirmState, setEmailConfirmState] = useState({
    emailConfirmText: emailAlert,
  });

  // This function verifies that the user's password is between 8-20 characters in length.

  const [passwordLengthState, setPasswordLengthState] = useState({
    lengthText: lengthAlert,
  });

  // Added this useEffect to ensure the email and password fields show the correct placeholder on page load/reload
  useEffect(() => {
    setNameState({
      nameBackground: "bg-white",
      nameHolder: t("usernamePlace"),
    });

    setEmailState({
      emailBackground: "bg-white",
      emailHolder: t("emailLabel"),
    });

    setPasswordState({
      passwordBackground: "bg-white",
      passwordHolder: t("passwordLabel"),
    });

    setPasswordConfirmState({
      passwordConfirmBackground: "bg-white",
      passwordConfirmHolder: t("passwordConfirm"),
    });
  }, [t]); // Dependency array includes 't' to update when translations are loaded

  useEffect(() => {
    AlwaysTop();
  }, []);

  return (
    <div className='flex flex-col items-center min-h-screen'>
      <div className='w-full max-w-md px-6'>
        <div className='flex items-center pt-10'>
          <img
            alt={t("altText:verticalDivider")}
            src='./../assets/icons/divider-vertical.svg'
            className='h-10 mr-4'
          />
          <h1 className='text-3xl font-bold'>{t("signUp")}</h1>
        </div>

        {/* <div className="inline-flex items-center justify-center w-full">
          <hr className="w-full h-1 my-8 bg-cromboOrangeFade border-0 rounded" />
          <span className="absolute px-1 text-xl text-zinc-600 bg-background">
            {t('signUpWith')}
          </span>
        </div> */}

        {/* <article className="flex justify-center pb-6 mb-4 lg:pb-10 pt-4">
          <Link to="/shopping-home">
            <div className="bg-white drop-shadow-lg rounded-md w-20 h-14 flex justify-center items-center mr-12 hover:cursor-pointer">
              <img
                alt={t("altText:facebook")}
                src="./../assets/icons/facebook-logo.png"
                width={35}
              />
            </div>
          </Link>
          <div className="bg-white drop-shadow-lg rounded-md w-20 h-14 flex justify-center items-center mr-12 hover:cursor-pointer">
            <img
              alt={t("altText:google")}
              src="./../assets/icons/google-logo.png"
              width={35}
            />
          </div>
          <div className="bg-white drop-shadow-lg rounded-md w-20 h-14 flex justify-center items-center hover:cursor-pointer">
            <img
              alt={t("altText:apple")}
              src="./../assets/icons/apple-logo.png"
              width={35}
            />
          </div>
        </article> */}

        <form
          className='w-full'
          onSubmit={async (e) => {
            try {
              let name = e.target.name.value;
              let email = e.target.email.value;
              let password = e.target.password.value;
              let passwordConfirm = e.target.passwordConfirm.value;
              let termsConditions = e.target.terms.checked;

              let userData = {
                username: name,
                email,
                password,
                termsConditions,
              };

              let emailCheck = email.search(/@/);
              let passwordLength = password.length;

              e.preventDefault();
              setIsLoading(true);

              if (
                name === "" ||
                email === "" ||
                password === "" ||
                passwordConfirm === "" ||
                termsConditions === false ||
                emailCheck === -1
              ) {
                if (name === "") {
                  setIsLoading(false);
                  setNameState({
                    ...nameState,
                    nameBackground: "bg-red-200",
                    nameHolder: t("invalidUsername"),
                  });
                }

                if (email === "") {
                  setIsLoading(false);
                  setEmailState({
                    ...emailState,
                    emailBackground: "bg-red-200",
                    emailHolder: t("invalidEmail"),
                  });
                }

                if (password === "") {
                  setIsLoading(false);
                  setPasswordState({
                    ...passwordState,
                    passwordBackground: "bg-red-200",
                    passwordHolder: t("passwordPlace"),
                  });
                }

                if (passwordConfirm === "") {
                  setIsLoading(false);
                  setPasswordConfirmState({
                    ...passwordConfirmState,
                    passwordConfirmBackground: "bg-red-200",
                    passwordConfirmHolder: t("passwordConfirm"),
                  });
                }

                if (termsConditions === false) {
                  setIsLoading(false);
                  setTermState({
                    ...termState,
                    termText: t("invalidTerms"),
                  });
                }

                if (emailCheck === -1 && email !== "") {
                  setIsLoading(false);
                  setEmailConfirmState({
                    ...emailConfirmState,
                    emailConfirmText: t("invalidEmail"),
                  });
                }

                return;
              }

              if (password !== passwordConfirm) {
                setIsLoading(false);
                setPasswordMatchState({
                  ...passwordMatchState,
                  passwordMatchText: t("passwordMismatch"),
                  passwordMatchColor: "text-red-500",
                });

                return;
              }

              if (passwordLength < 8 || passwordLength > 20) {
                setIsLoading(false);
                setPasswordLengthState({
                  ...passwordLengthState,
                  lengthText: t("passwordPlace"),
                });

                return;
              }

              const signupResponse = await postReq("/user/register", userData);
              if (signupResponse?.user?._id) {
                setIsLoading(false);
                localStorage.setItem("userData", JSON.stringify(userData));
                navigateLogin("/signup-confirmation");
              }
            } catch (error) {
              setIsLoading(false);
              console.error("Internal server error at signup:", error);
            }
          }}>
          <div className='w-full mb-6'>
            <label
              className='text-zinc-400 font-semibold'
              htmlFor='name'>
              {t("usernameLabel")}
            </label>
            <input
              name='name'
              className={`${nameState.nameBackground} form-field w-full px-4 py-2`}
              placeholder={nameState.nameHolder}
              onClick={() => {
                setNameState({
                  ...nameState,
                  nameBackground: "bg-white",
                  nameHolder: t("usernamePlace"),
                });
              }}
            />
          </div>

          <div className='w-full mb-6'>
            <label
              className='text-zinc-400 font-semibold'
              htmlFor='email'>
              {t("emailLabel")}
            </label>
            <h2 className='text-red-500 font-semibold pl-2 lg:flex-row text-sm lg:text-base'>
              {emailConfirmState.emailConfirmText}
            </h2>
            <input
              name='email'
              className={`${emailState.emailBackground} form-field w-full px-4 py-2`}
              placeholder={emailState.emailHolder}
              onClick={() => {
                setEmailState({
                  ...emailState,
                  emailBackground: "bg-white",
                  emailHolder: t("emailPlace"),
                });
                setEmailConfirmState({
                  ...emailConfirmState,
                  emailConfirmText: "",
                });
              }}
            />
          </div>

          <div className='w-full mb-6'>
            <label
              className='text-zinc-400 font-semibold'
              htmlFor='password'>
              {t("createPassword")}
            </label>
            <h2 className='text-red-500 font-semibold pl-2 lg:flex-row text-sm lg:text-base'>
              {passwordMatchState.passwordMatchText}
            </h2>
            <input
              type='password'
              name='password'
              className={`${passwordState.passwordBackground} form-field w-full px-4 py-2`}
              placeholder={passwordState.passwordHolder}
              onClick={() => {
                setPasswordState({
                  ...passwordState,
                  passwordBackground: "bg-white",
                  passwordHolder: t("passwordPlace"),
                });
                setPasswordMatchState({
                  ...passwordMatchState,
                  passwordMatchText: "",
                  passwordMatchColor: "text-red-500",
                });
                setPasswordLengthState({
                  ...passwordLengthState,
                  lengthText: "",
                });
              }}
            />
          </div>

          <div className='w-full mb-6'>
            <label
              className='text-zinc-400 font-semibold'
              htmlFor='passwordConfirm'>
              {t("passwordConfirm")}
            </label>
            <input
              type='password'
              name='passwordConfirm'
              className={`${passwordConfirmState.passwordConfirmBackground} form-field w-full px-4 py-2`}
              placeholder={passwordConfirmState.passwordConfirmHolder}
              onClick={() => {
                setPasswordConfirmState({
                  ...passwordConfirmState,
                  passwordConfirmBackground: "bg-white",
                  passwordConfirmHolder: t("passwordConfirm"),
                });
                setPasswordMatchState({
                  ...passwordMatchState,
                  passwordMatchText: "",
                  passwordMatchColor: "text-red-500",
                });
                setPasswordLengthState({
                  ...passwordLengthState,
                  lengthText: "",
                });
              }}
            />
          </div>

          <div className='w-full mb-6'>
            <input
              name='terms'
              type='checkbox'
              onClick={() => {
                setTermState({
                  ...termState,
                  termText: "",
                });
              }}
              className='mb-1 hover:cursor-pointer'
            />
            <label className='pl-2'>
              {t("termsConfirm")}
              <a
                href='https://24mobler.se/en/kopvillkor/'
                target='_blank'
                className='text-cromboOrange font-semibold hover:cursor-pointer hover:underline inline-flex items-center'>
                Terms & Conditions{" "}
                <img
                  src='./../assets/icons/new-window-icon.png'
                  width={16}
                  className='mb-2'
                  alt={t("altText:newTab")}
                />
              </a>
            </label>
            <p className='text-red-500 font-semibold'>{termState.termText}</p>
          </div>

          <button
            type='submit'
            className={`btn-main w-full flex items-center justify-center mb-12 ${
              isLoading ? "opacity-50 cursor-not-allowed" : ""
            }`}>
            {isLoading ? (
              <span>Loading...</span>
            ) : (
              <span className='text-white text-lg lg:text-2xl text-center font-semibold'>
                {t("signUp")}
              </span>
            )}
          </button>
        </form>
      </div>
      <h2 className='mx-auto text-lg'>
        {t("loginPrompt")}
        <Link
          to='/welcome-login'
          className='text-cromboOrange pl-1 font-bold hover:cursor-pointer hover:underline'>
          {t("login")}
        </Link>
      </h2>
    </div>
  );
}

export default WelcomeSignup;
