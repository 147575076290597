import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { HiOutlineArrowLongLeft } from "react-icons/hi2";
import { postReq } from "../../configs/ApiUrl";
import { useTranslation } from 'react-i18next';
import AlwaysTop from "./util/AlwaysTop";

function AdminRegisterNew() {
  const { t } = useTranslation(['forms', 'altText', 'admin']);
  const navigateLogin = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  let nameInputColor = "bg-white";
  let emailInputColor = "bg-white";
  let companyInputColor = "bg-white";
  let passwordInputColor = "bg-white";
  let passwordConfirmInputColor = "bg-white";

  let namePlace = "";
  let emailPlace = "";
  let companyPlace = "";
  let passwordPlace = "";
  let passwordConfirmPlace = "";

  let passwordAlert = "";
  let colorAlert = "text-black";

  let termAlert = "";
  let emailAlert = "";
  let lengthAlert = "";

  const [nameState, setNameState] = useState({
    nameBackground: nameInputColor,
    nameHolder: t('usernamePlace'),
  });

  const [emailState, setEmailState] = useState({
    emailBackground: emailInputColor,
    emailHolder: t('emailLabel'),
  });

  const [companyState, setCompanyState] = useState({
    companyBackground: companyInputColor,
    companyHolder: t('companyNameLabel'),
  });

  const [passwordState, setPasswordState] = useState({
    passwordBackground: passwordInputColor,
    passwordHolder: t('passwordLabel'),
  });

  const [passwordConfirmState, setPasswordConfirmState] = useState({
    passwordConfirmBackground: passwordConfirmInputColor,
    passwordConfirmHolder: t('passwordConfirm'),
  });

  const [termState, setTermState] = useState({
    termText: termAlert,
  });

  const [passwordMatchState, setPasswordMatchState] = useState({
    passwordMatchText: passwordAlert,
    passwordMatchColor: colorAlert,
  });

  const [emailConfirmState, setEmailConfirmState] = useState({
    emailConfirmText: emailAlert,
  });

  const [passwordLengthState, setPasswordLengthState] = useState({
    lengthText: lengthAlert,
  });

  useEffect(() => {
    setNameState({
      nameBackground: "bg-white",
      nameHolder: t('usernamePlace'),
    });

    setEmailState({
      emailBackground: "bg-white",
      emailHolder: t('emailLabel'),
    });

    setCompanyState({
      companyBackground: "bg-white",
      companyHolder: t('companyNameLabel'),
    });

    setPasswordState({
      passwordBackground: "bg-white",
      passwordHolder: t('passwordLabel'),
    });

    setPasswordConfirmState({
      passwordConfirmBackground: "bg-white",
      passwordConfirmHolder: t('passwordConfirm'),
    });
  }, [t]);

  useEffect(() => {
    AlwaysTop();
  }, []);

  return (
    <div className="flex flex-col items-center min-h-screen">
      <header className="w-full flex items-center justify-between bg-white drop-shadow">
        <Link to="/admin-menu" className="flex inline-flex back-btn mx-4">
          <HiOutlineArrowLongLeft size={30} color="white" />
        </Link>
        <article className="w-full flex-col items-center justify-center mr-16 my-6">
          <h1 className="text-3xl font-bold flex-grow text-center">
            {t("admin:registerNewAdminButton")}
          </h1>
          <p className="mt-1 text-sm font-semibold text-center text-gray-500">
            {t("admin:registerNewAdminExplainer")}
          </p>
        </article>
      </header>
      <div className="p-16 bg-white min-h-screen">
      <form
        onSubmit={async (e) => {
          e.preventDefault();

          let name = e.target.name.value;
          let email = e.target.email.value;
          let company = e.target.company.value;
          let password = e.target.password.value;
          let passwordConfirm = e.target.passwordConfirm.value;
          let termsConditions = e.target.terms.checked;

          let userData = { username: name, email, companyName: company, password, termsConditions, role: "subadmin" };

          let emailCheck = email.search(/@/);
          let passwordLength = password.length;

          setIsLoading(true);

          if (
            name === "" ||
            email === "" ||
            company === "" ||
            password === "" ||
            passwordConfirm === "" ||
            termsConditions === false ||
            emailCheck === -1
          ) {
            if (name === "") {
              setIsLoading(false);
              setNameState({
                ...nameState,
                nameBackground: (nameInputColor = "bg-red-200"),
                nameHolder: (namePlace = t('invalidUsername')),
              });
            }

            if (email === "") {
              setIsLoading(false);
              setEmailState({
                ...emailState,
                emailBackground: (emailInputColor = "bg-red-200"),
                emailHolder: (emailPlace = t('invalidEmail')),
              });
            }

            if (company === "") {
              setIsLoading(false);
              setCompanyState({
                ...companyState,
                companyBackground: (companyInputColor = "bg-red-200"),
                companyHolder: (companyPlace = t('invalidCompanyName')),
              });
            }

            if (password === "") {
              setIsLoading(false);
              setPasswordState({
                ...passwordState,
                passwordBackground: (passwordInputColor = "bg-red-200"),
                passwordHolder: (passwordPlace = t('passwordPlace')),
              });
            }

            if (passwordConfirm === "") {
              setIsLoading(false);
              setPasswordConfirmState({
                ...passwordConfirmState,
                passwordConfirmBackground: (passwordConfirmInputColor = "bg-red-200"),
                passwordConfirmHolder: (passwordConfirmPlace = t('passwordConfirm')),
              });
            }

            if (termsConditions === false) {
              setIsLoading(false);
              setTermState({
                ...termState,
                termText: (termAlert = t('invalidTerms')),
              });
            }

            if (emailCheck === -1 && email !== "") {
              setIsLoading(false);
              setEmailConfirmState({
                ...emailConfirmState,
                emailConfirmText: (emailAlert = t('invalidEmail')),
              });
            }

            return;
          }

          if (password !== passwordConfirm) {
            setIsLoading(false);
            setPasswordMatchState({
              ...passwordMatchState,
              passwordMatchText: (passwordAlert = t('passwordMismatch')),
              passwordMatchColor: (colorAlert = "text-red-500"),
            });

            return;
          }

          if (passwordLength < 8 || passwordLength > 20) {
            setIsLoading(false);
            setPasswordLengthState({
              ...passwordLengthState,
              lengthText: (lengthAlert = t('passwordPlace')),
            });

            return;
          }

          try {
            const signupResponse = await postReq("/user/register", userData);
            if (signupResponse?.user?._id) {
              setIsLoading(false);
              navigateLogin("/subadmin-login");
            }
          } catch (error) {
            setIsLoading(false);
            console.error("Internal server error at signup:", error);
          }
        }}
      >
        <h2 className="font-semibold">{t('usernameLabel')}</h2>
        <input
          name="name"
          className={`${nameState.nameBackground} form-field w-full mb-6`}
          placeholder={nameState.nameHolder}
          onClick={() => {
            setNameState({
              ...nameState,
              nameBackground: (nameInputColor = "bg-white"),
              nameHolder: (namePlace = t('usernamePlace')),
            });
          }}
        />
        <div className="flex flex-col">
          <h2 className="font-semibold">{t('emailLabel')}</h2>
          <h2 className="text-red-500 font-semibold pl-2 lg:flex-row text-sm lg:text-base">
            {emailConfirmState.emailConfirmText}
          </h2>
        </div>
        <input
          name="email"
          className={`${emailState.emailBackground} form-field w-full mb-6`}
          placeholder={emailState.emailHolder}
          onClick={() => {
            setEmailState({
              ...emailState,
              emailBackground: (emailInputColor = "bg-white"),
              emailHolder: (emailPlace = t('emailPlace')),
            });
            setEmailConfirmState({
              ...emailConfirmState,
              emailConfirmText: (emailAlert = ""),
            });
          }}
        />
        <h2 className="font-semibold">{t('companyNameLabel')}</h2>
        <input
          name="company"
          className={`${companyState.companyBackground} form-field w-full mb-6`}
          placeholder={companyState.companyHolder}
          onClick={() => {
            setCompanyState({
              ...companyState,
              companyBackground: (companyInputColor = "bg-white"),
              companyHolder: (companyPlace = t('companyNameLabel')),
            });
          }}
        />
        <h2 className="text-red-500 font-semibold text-sm lg:text-base">
          {passwordLengthState.lengthText}
        </h2>
        <div className="flex flex-col lg:flex-row">
          <h2 className="font-semibold">{t('createPassword')}</h2>
          <h2
            className={
              passwordMatchState.passwordMatchColor +
              " font-semibold pl-2 text-sm lg:text-base"
            }
          >
            {passwordMatchState.passwordMatchText}
          </h2>
        </div>
        <input
          type="password"
          name="password"
          className={`${passwordState.passwordBackground} form-field w-full mb-6`}
          placeholder={passwordState.passwordHolder}
          onClick={() => {
            setPasswordState({
              ...passwordState,
              passwordBackground: (passwordInputColor = "bg-white"),
              passwordHolder: (passwordPlace = t('passwordPlace')),
            });
            setPasswordMatchState({
              ...passwordMatchState,
              passwordMatchText: (passwordAlert = ""),
              passwordMatchColor: (colorAlert = "text-red-500"),
            });
            setPasswordLengthState({
              ...passwordLengthState,
              lengthText: (lengthAlert = ""),
            });
          }}
        />
        <div className="flex flex-col lg:flex-row">
          <h2 className="font-semibold">{t('passwordConfirm')}</h2>
          <h2
            className={
              passwordMatchState.passwordMatchColor +
              " font-semibold pl-2 text-sm lg:text-base"
            }
          >
            {passwordMatchState.passwordMatchText}
          </h2>
        </div>
        <input
          type="password"
          name="passwordConfirm"
          className={`${passwordConfirmState.passwordConfirmBackground} form-field w-full mb-2`}
          placeholder={passwordConfirmState.passwordConfirmHolder}
          onClick={() => {
            setPasswordConfirmState({
              ...passwordConfirmState,
              passwordConfirmBackground: (passwordConfirmInputColor = "bg-white"),
              passwordConfirmHolder: (passwordConfirmPlace = t('passwordConfirmPlace')),
            });
            setPasswordMatchState({
              ...passwordMatchState,
              passwordMatchText: (passwordAlert = ""),
              passwordMatchColor: (colorAlert = "text-red-500"),
            });
            setPasswordLengthState({
              ...passwordLengthState,
              lengthText: (lengthAlert = ""),
            });
          }}
        />
        <div className="mt-2 mb-12">
          <input
            name="terms"
            type="checkbox"
            onClick={() => {
              setTermState({
                ...termState,
                termText: (termAlert = ""),
              });
            }}
            className="mb-1 hover:cursor-pointer"
          />
          <label className="pl-2">
            {t('termsConfirm')}  
             <a href="https://24mobler.se/en/kopvillkor/" target="_blank" className="text-cromboOrange font-semibold hover:cursor-pointer hover:underline inline-flex items-center">Terms & Conditions <img src="./../assets/icons/new-window-icon.png" width={16} className="mb-2" alt={t('altText:newTab')} /></a>
          </label>
          <p className="text-red-500 font-semibold">{termState.termText}</p>
        </div>
        <button
          type="submit"
          className={`btn-main w-full flex items-center justify-center mb-12 ${isLoading ? "opacity-50 cursor-not-allowed" : ""}`}
        >
          {isLoading ? (
            <span>Loading...</span>
          ) : (
            <span className="text-white text-lg lg:text-2xl text-center font-semibold">
              {t('signUp')}
            </span>
          )}
        </button>
      </form>
      </div>
    </div>
  );
}

export default AdminRegisterNew;